<template>
	<div class="map-scale" :style="{
		width: width
	}">
    <div class="ruler">
			<div class="ruler-block upper-first-piece"></div>
			<div class="ruler-block upper-second-piece"></div>
			<div class="ruler-block lower-first-piece"></div>
			<div class="ruler-block lower-second-piece"></div>
		</div>
		<div class="label-div">
			<span class="label">0</span>
			<span class="label first-number">{{label1}}</span>
			<span class="label second-number">{{label2}}</span>
		</div>
	</div>
</template>

<script>
import leaflet, { Map } from 'leaflet'
import {Calculator} from '@/plugins/calculator';

export default {
	name: 'MapScale',
	props: {
		maxWidth: {
			default: 300,
			type: Number
		},
		map: Object,
	},
  data() {
    return {
			width: null,
			label1: '1',
			label2: '2km',
			calculator: null,
    }
  },
	methods: {
		maxTrueWidth() {
			if(window.innerWidth < 576) {
				return this.$props.maxWidth *0.8;
			}
			return this.$props.maxWidth;
		},
		init(map){
			this.map = map;
			this.update();
			map.on('zoomend', () => {
				this.update();
			});
		},
		update() {
			const bounds = this.map.getBounds();
			let centerLat = bounds.getCenter().lat;
			let i = 6378137 * Math.PI * Math.cos(centerLat * Math.PI / 180);
			let n = i * (bounds.getNorthEast().lng - bounds.getSouthWest().lng) / 180;
			let mapSize = this.map.getSize();
			if(mapSize.x <= 0) return;
			const a = n * ((this.maxTrueWidth()) / mapSize.x);

			if(a > 500) {
				centerLat = a / 1000;
				i = this._getRoundNum(centerLat);
				mapSize = this._getRoundNum(centerLat / 2);
				this.width = this._getScaleWidth(i / centerLat) + "px";
				this.label1 = this.calculator.distanceFromKm(mapSize, 0).replace(/[^0-9]+/, '');
				this.label2 = this.calculator.distanceFromKm(i, 0).replace(' ', '');
			}else{
				n = this._getRoundNum(a);
				let s = this._getRoundNum(a / 2);
				this.width = this._getScaleWidth(n / a) + "px";
				this.label1 = s;
				this.label2 = n + "m"
			}

		},
		_getScaleWidth: function (t) {
        return Math.round(this.maxTrueWidth() * t) - 10
    },
    _getRoundNum: function (t) {
			if(t < 1) return Math.round(t * 100) / 100;
			const e = Math.pow(10, (Math.floor(t) + "").length - 1); // 1
			let i = t / e;

			if(i >= 10) i = 10;
			else if(i >= 5) i = 5;
			else if(i >= 3) i = 3;
			else if(i >= 2) i = 2;
			else i = 1;

			return i * e;
    }
	},
	mounted() {
		this.calculator = new Calculator(this)
	}
}
</script>

<style scoped>

.map-scale {
	margin: 10px;
	margin-right: 25px;
}

.ruler {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 10px;
    background-color: White;
    border: 1px solid #444444;
}
.upper-first-piece {
    top: 0%;
    left: 0%;
    width: 25%;
}
.upper-second-piece {
    top: 0%;
    left: 50%;
    width: 25%;
}
.lower-first-piece {
    top: 50%;
    left: 25%;
    width: 25%;
}
.lower-second-piece {
    top: 50%;
    left: 75%;
    width: 30%;
}


.ruler-block {
    overflow: hidden;
    position: absolute;
    height: 50%;
    background-color: #444444;
}

.label-div {
    position: relative;
    width: 100%;
}

.label {
    position: absolute;
    width: 10%;
    text-align: center;
    color: black;
    font: sans-serif;
    font-weight: bold;
    font-size: 14px;
    height: 5px;
    top: -1px;
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}

@media (max-width: 576px) {
	.map-scale  {
		padding-right: 5px;
	}
	.label {
		font-size: 12px;
	}
}

.first-number {
    left: 45%;
}

.second-number {
    left: 90%;
}
</style>
