import CheckboxElement from "@/components/menu/menuComponent/CheckboxElement";
import SliderElement from "@/components/menu/menuComponent/SliderElement";

export function FlightAreaManager(map, viewManager, iframeMode) {

	this.map = map;
	this.viewManager = viewManager;
	this.airspaceTypes = [];
	this.airspacesByName = {};
	this.frontiers = {};
	this.polygons = [];
	this.maxElev = 0;
	this.maxElevFilterSet = false;
	this.maxElevFilter = 0;
	this.maxAllowedElevFilter = 10000;
	this.hungaryFrontier = [];

	this.frontiersToReverse = {
		'HUNGARY_UKRAINE': true,
		'AUSTRIA_HUNGARY': true,
		'HUNGARY_SERBIAANDMONTENEGRO': true,
		'CROATIA_HUNGARY': true
	};

	this.colors = [
		'#ff0000',
		'#00ff00',
		'#0000ff',
		'#00ffff',
		'#ff00ff',
		'#ffff00',
	];

	this.colorIndex = 0;

	this.airspaceTypeDataDefault = {
		'P': 					          { weight: 3200, enabled: true, 	color: '#5e0707', filled: iframeMode },
		'R': 					          { weight: 3100, enabled: true, 	color: '#750707', filled: iframeMode },
		'CTR': 					        { weight: 3000, enabled: true, 	color: '#0c406e', dashed: true},
		"CTR-P": 				        { weight: 2900, enabled: true, 	color: '#0c406e', dashed: true},
		'TMA': 					        { weight: 2800, enabled: true, 	color: '#4e87ba' },
		"TMA-P": 				        { weight: 2700, enabled: true, 	color: '#4e87ba', },
		"LARA_FUA": 			      { weight: 2600, enabled: true, 	color: '#dc143c', nameOverride: "Activated", filled: iframeMode }, //Activated Airspace
		"PUBLISHED": 			      { weight: 2550, enabled: true, 	color: '#FCAC1E', nameOverride: "Published", filled: iframeMode }, //Published Airspace
		'NATURE RESERVE AREA': 	{ weight: 2500, enabled: true, 	color: '#32ab32' },
		"DRONE_ZONE": 			    { weight: 2400, enabled: true, color: '#8fbc8f' },
		'TEMP-ACTIVE': 			    { weight: 2300, enabled: true, 	color: '#800180', nameOverride: "Reserved" }, //Temp Airspace
		'MCTR': 				        { weight: 2200, enabled: false, color: '#0c406e', dashed: true},
		'MTMA': 				        { weight: 2100, enabled: false, color: '#4e87ba' },
		'CTA': 					        { weight: 2000, enabled: false, color: '#7eb9ed', dashed: true },
		"CTA-P": 				        { weight: 1900, enabled: false, color: '#7eb9ed', dashed: true },
		'TIZ': 					        { weight: 1800, enabled: false, color: '#fcba03' },
		"RAS": 					        { weight: 1700, enabled: false, color: '#483d8b' },
		'D': 					          { weight: 1600, enabled: false, color: '#ff1493' },
		'TRA': 					        { weight: 1500, enabled: false, color: '#ff4500' },
		'DROPZONE': 			      { weight: 1400, enabled: false, color: '#ee82ee' },
		'DropZone': 			      { weight: 1400, enabled: false, color: '#ee82ee', nameOverride: "DROPZONE" },
		"AEROBATIC": 						{ weight: 1300, enabled: false, color: '#696969' },
		"GLIDER": 							{ weight: 1200, enabled: false, color: '#696969' },
		"TransponderMandatory":	{ weight: 1100, enabled: false, color: '#696969' },
		"RadioMandatory": 		  { weight: 1000, enabled: false, color: '#696969' },
		'COUNTRY_CTA': 					{ weight: 900, 	enabled: false, color: '#7eb9ed' },
		"N/A": 									{ weight: 800, 	enabled: false, color: '#000000' },
		"A": 					          { weight: 700, 	enabled: false, color: '#ffa500' }, //@Deprecated
		'GLDR': 				        { weight: 600, 	enabled: false, color: '#9dcff2' }, //@Deprecated
		'TRA-ACTIVE': 			    { weight: 500, 	enabled: false, color: '#ff00ff', canBeActivated: true }, //@Deprecated
		'D-ACTIVE': 			      { weight: 400, 	enabled: false, color: '#800180', canBeActivated: true }, //@Deprecated
		'MTMA-ACTIVE': 			    { weight: 300, 	enabled: false, color: '#4e87ba', canBeActivated: true }, //@Deprecated
		'TEMP': 				        { weight: 200, 	enabled: false, color: '#800180', canBeActivated: true }, //@Deprecated
		'BORDER': 				      { weight: 100, 	enabled: false, color: '#777777' }, //@Deprecated
	};

	this.resetAirspaceTypes = function() {
		window.consentedStorage.removeItem('airspaceTypeData');
	};

	this.loadAirspaceTypes = function() {
		this.airspaceTypeData = JSON.parse(JSON.stringify(this.airspaceTypeDataDefault));

		let _str = window.consentedStorage.getItem('airspaceTypeData');
		if(_str) {
			let d = JSON.parse(_str);
			for(let _type in d) {
				let o = d[_type];
				if(typeof o === 'boolean') {
					this.airspaceTypeData[_type].enabled = o;
				}
				else {
					this.airspaceTypeData[_type].enabled = d[_type].enabled;
				}
			}
		}

		_str = window.consentedStorage.getItem('maxElevFilter');
		if(_str) {
			this.maxElevFilter = parseInt(_str);
			this.maxElevFilter = Math.min(this.maxAllowedElevFilter, this.maxElevFilter);
			this.maxElevFilterSet = true;
		}
	};

	//this.resetAirspaceTypes();
	this.loadAirspaceTypes();

	this.makeRightMenuComponents = function() {
		let components = [];
		components.push({
			component: SliderElement,
			props: {
				title: this.viewManager.$t('flightareamanager.flight-altitude'),
				default: this.maxElevFilter,
				step: 100,
				min: 0,
				max: Math.min(this.maxAllowedElevFilter, this.maxElev),
				unit: 'FT',
				event: (elev) => {
					this.toggleAirspacesByElevation(elev);
					this.toggleAirspaces();
				}
			}
		});

		this.airspaceTypes.sort((a, b) => {
			let w = this.airspaceTypeData[a];
			let x = this.airspaceTypeData[b];
			if(w && x) {
				return x.weight - w.weight;
			}
			return 0;
		});

		for(let airspaceType of this.airspaceTypes) {
			const type = this.airspaceTypeData[airspaceType];
			components.push({
				component: CheckboxElement,
				props: {
					title: type && type.nameOverride ? type.nameOverride : airspaceType,
					value: airspaceType,
					checked: (() => { let w = this.airspaceTypeData[airspaceType]; if(w) return w.enabled; return false; })(),
					event: (type, on) => {
						this.toggleAirspacesByType(type, on);
						this.toggleAirspaces();
					}
				}
			});
		}
		return components;
	};

	this.fetchTempAirspaces = async function() {
		try {
			let tempAirspaces = await this.viewManager.$rest.getTempAirspaces();
			if(tempAirspaces.length === 0) {
				return this.viewManager.$store.getters.getTempAirspaces;
			}
			await this.viewManager.$store.dispatch('storeTempAirspaces', tempAirspaces);
			return tempAirspaces;
		}
		catch(errorMsg) {
			return this.viewManager.$store.getters.getTempAirspaces;
		}
	}

	this.fetchNewAirspaces = async function() {
		try {
			let airspaces = await this.viewManager.$rest.getAirspaces();
			if(airspaces.length === 0){
				return this.viewManager.$store.getters.getAirspaces;
			}
			await this.viewManager.$store.dispatch('storeAirspaces', airspaces);
			return airspaces;
		}
		catch(errorMsg) {
			console.log(errorMsg);
			return this.viewManager.$store.getters.getAirspaces;
		}
	}

	this.loadTempAirspaces = async function() {
		await this.loadFrontiers('ofmx_lh.ofmx');

		let tempAirspaces = await this.fetchTempAirspaces();

		for(let ti = tempAirspaces.length - 1; ti >= 0; ti--) {
			let tempAirspace = tempAirspaces[ti];

			let color;
			let d = this.airspaceTypeDataDefault['TEMP-ACTIVE'];
			if(d && d.color) {
				color = d.color;
			}
			else {
				color = '#ff0000';
			}



			let airspace = {
				id: tempAirspace.id,
				name: /*'#' + tempAirspace.orderNumber + ' ' +*/ tempAirspace.comment,
				type: 'TEMP-ACTIVE',
				codeDistVerUpper: '',
				valDistVerUpper: tempAirspace.maxHeight_str,
				uomDistVerUpper: '',
				codeDistVerLower: '',
				valDistVerLower: tempAirspace.minHeight_str,
				uomDistVerLower: '',
				lowerInFeet: tempAirspace.minHeight,
				coordinates: [],
				color: color,
				comment: tempAirspace.comment,
				day: tempAirspace.day,
				startTime: tempAirspace.startTime,
				endTime: tempAirspace.endTime,
				active: true
			};

			for(let i = 0; i < tempAirspace.borderPoints.length; i++) {
				let bp = tempAirspace.borderPoints[i];
				if(bp.radius) {
					airspace.coordinates.push(this.makeFullCircle([bp.latitude, bp.longitude], bp.radius, 32));
				}
				else if(bp.borderPoint) {
					this.extendAlongBorder(airspace, [bp.latitude, bp.longitude], null, null, [bp.endLatitude, bp.endLongitude], i);
				}
				else {
					airspace.coordinates.push([bp.latitude, bp.longitude]);
				}
			}


			this.makePolygon(airspace);


			if(!this.airspaceTypes.includes('TEMP-ACTIVE')) {
				this.airspaceTypes.push('TEMP-ACTIVE');
			}
		}
	};


	this.loadFrontiers = function(filename) {
		this.frontiers = {};

		return new Promise((resolve, reject) => {
			let oReq = new XMLHttpRequest();
			oReq.onload = async (e) => {

				let parser = new DOMParser();
				let xmlDoc = parser.parseFromString(oReq.response, 'text/xml');

				let root = xmlDoc.childNodes[0];

				let airspaces = {};
				this.airspacesByName = {};

				for(let child of root.childNodes) {
					if(child.nodeType == Node.ELEMENT_NODE) {

						if(child.nodeName == 'Gbr') {
							let gbrUid = child.getElementsByTagName('GbrUid')[0];
							let name = gbrUid.getElementsByTagName('txtName')[0].childNodes[0].nodeValue;
							if(name.indexOf('HUNGARY') >= 0) {
								let coordinates = [];
								let gbvs = child.getElementsByTagName('Gbv');
								if(this.frontiersToReverse[name]) {
									for(let g = gbvs.length - 1; g >= 0; g--) {
										let gbv = gbvs[g];
										let lat = this.parseGeoLat(gbv.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
										let lng = this.parseGeoLon(gbv.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
										coordinates.push([lat, lng]);
									}
								}
								else {
									for(let gbv of gbvs) {
										let lat = this.parseGeoLat(gbv.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
										let lng = this.parseGeoLon(gbv.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
										coordinates.push([lat, lng]);
									}
								}
								this.frontiers[name] = coordinates;

								let airspace = {
									id: name,
									name: name,
									type: 'BORDER',
									coordinates: coordinates,
									active: true
								};

								airspaces[name] = airspace;
								this.airspacesByName[name] = airspace;
							}
						}
					}
				}

				this.hungaryFrontier = [];
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_SLOVENIA']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['AUSTRIA_HUNGARY']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_SLOVAKREPUBLIC']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_UKRAINE']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_ROMANIA']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_SERBIAANDMONTENEGRO']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['CROATIA_HUNGARY']);

				resolve();
			};
			oReq.open('GET', filename);
			oReq.send();
		});
	};


	/**
	 * @deprecated Since we have the new hungarocontrol api. Use loadNewAirspaces instead.
	 */
	this.loadAirspaces = function(filename) {
		this.clear();
		this.polygons = [];
		this.airspaceTypes = [];
		return new Promise((resolve, reject) => {
			let oReq = new XMLHttpRequest();
			oReq.onload = async (e) => {

				let parser = new DOMParser();
				let xmlDoc = parser.parseFromString(oReq.response, 'text/xml');

				let root = xmlDoc.childNodes[0];

				let airspaceTypesMap = {};

				let airspaces = {};
				this.airspacesByName = {};

				for(let child of root.childNodes) {
					if(child.nodeType == Node.ELEMENT_NODE) {

						if(child.nodeName == 'Ase') {
							let name = child.getElementsByTagName('txtName')[0].childNodes[0].nodeValue;
							let aseUid = child.getElementsByTagName('AseUid')[0];
							let id = aseUid.getAttribute('mid');
							let type = aseUid.getElementsByTagName('codeType')[0].childNodes[0].nodeValue;

							if(type == 'CTA' && name == 'BUDAPEST') {
								continue;
							}

							if(type == 'SECTOR' || type == 'ACRO' || type == 'FIR') {
								continue;
							}

							let active = true;

							if((type == 'R' || type == 'D') && name.indexOf('TRA') >= 0) {
								type = 'TRA';
							}
							else if(type == 'CTR' && (name == 'PAPA' || name == 'KECSKEMET' || name == 'SZOLNOK')) {
								type = 'MCTR';
							}
							else if(type == 'TMA' && name == 'PAPA') {
								type = 'MTMA';
							}
							else if(type == 'HPGLDR') {
								type = 'DROPZONE';
							}
							else if(type == 'ATZ') {
								type = 'TIZ';
							}
							else if(type == 'NRA') {
								type = 'NATURE RESERVE AREA';
							}

							if(type == 'MTMA' || type == 'D' || type == 'TRA') {
								active = false;
							}

							airspaceTypesMap[type] = true;

							let codeDistVerUpper = child.getElementsByTagName('codeDistVerUpper')[0].childNodes[0].nodeValue;
							let valDistVerUpper = child.getElementsByTagName('valDistVerUpper')[0].childNodes[0].nodeValue;
							let uomDistVerUpper = child.getElementsByTagName('uomDistVerUpper')[0].childNodes[0].nodeValue;
							let codeDistVerLower = child.getElementsByTagName('codeDistVerLower')[0].childNodes[0].nodeValue;
							let valDistVerLower = child.getElementsByTagName('valDistVerLower')[0].childNodes[0].nodeValue;
							let uomDistVerLower = child.getElementsByTagName('uomDistVerLower')[0].childNodes[0].nodeValue;

							let lowerInFeet = 0;
							if(uomDistVerLower == 'FT') {
								lowerInFeet = valDistVerLower;
							}
							else if(uomDistVerLower == 'FL') {
								lowerInFeet = valDistVerLower * 100;
							}
							if(lowerInFeet > this.maxElev) {
								this.maxElev = lowerInFeet;
							}

							let airspace = {
								id: id,
								name: name,
								type: type,
								codeDistVerUpper: codeDistVerUpper,
								valDistVerUpper: valDistVerUpper,
								uomDistVerUpper: uomDistVerUpper,
								codeDistVerLower: codeDistVerLower,
								valDistVerLower: valDistVerLower,
								uomDistVerLower: uomDistVerLower,
								lowerInFeet: lowerInFeet,
								coordinates: [],
								active: active
							};

							airspaces[id] = airspace;
							this.airspacesByName[name] = airspace;
						}
						else if(child.nodeName == 'Gbr') {
							let gbrUid = child.getElementsByTagName('GbrUid')[0];
							let name = gbrUid.getElementsByTagName('txtName')[0].childNodes[0].nodeValue;
							if(name.indexOf('HUNGARY') >= 0) {
								let coordinates = [];
								let gbvs = child.getElementsByTagName('Gbv');
								if(this.frontiersToReverse[name]) {
									for(let g = gbvs.length - 1; g >= 0; g--) {
										let gbv = gbvs[g];
										let lat = this.parseGeoLat(gbv.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
										let lng = this.parseGeoLon(gbv.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
										coordinates.push([lat, lng]);
									}
								}
								else {
									for(let gbv of gbvs) {
										let lat = this.parseGeoLat(gbv.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
										let lng = this.parseGeoLon(gbv.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
										coordinates.push([lat, lng]);
									}
								}
								this.frontiers[name] = coordinates;

								let airspace = {
									id: name,
									name: name,
									type: 'BORDER',
									coordinates: coordinates,
									active: true
								};

								airspaces[name] = airspace;
								this.airspacesByName[name] = airspace;
							}
						}

					}
				}

				this.hungaryFrontier = [];
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_SLOVENIA']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['AUSTRIA_HUNGARY']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_SLOVAKREPUBLIC']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_UKRAINE']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_ROMANIA']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['HUNGARY_SERBIAANDMONTENEGRO']);
				this.hungaryFrontier = this.hungaryFrontier.concat(this.frontiers['CROATIA_HUNGARY']);

				await this.loadTempAirspaces();

				for(let child of root.childNodes) {
					if(child.nodeType == Node.ELEMENT_NODE) {

						if(child.nodeName == 'Abd') {
							let abdUid = child.getElementsByTagName('AbdUid')[0];
							let aseUid = abdUid.getElementsByTagName('AseUid')[0];
							let id = aseUid.getAttribute('mid');
							let airspace = airspaces[id];
							if(airspace) {
								let avxList = child.getElementsByTagName('Avx');
								for(let i = 0; i < avxList.length; i++) {
									let avx = avxList[i];
									let codeType = avx.getElementsByTagName('codeType')[0].childNodes[0].nodeValue;
									let lat = this.parseGeoLat(avx.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
									let lng = this.parseGeoLon(avx.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
									switch(codeType) {
										case 'GRC': {
											airspace.coordinates.push([lat, lng]);
											break;
										}
										case 'FNT': {
											airspace.coordinates.push([lat, lng]);

											let gbrUid = avx.getElementsByTagName('GbrUid')[0];
											let frontierName = gbrUid.getElementsByTagName('txtName')[0].childNodes[0].nodeValue;
											this.extendAlongBorder(airspace, [lat, lng], frontierName, avxList, null, i);
											break;
										}
										case 'CWA': {
											let latCenter = this.parseGeoLat(avx.getElementsByTagName('geoLatArc')[0].childNodes[0].nodeValue);
											let lngCenter = this.parseGeoLon(avx.getElementsByTagName('geoLongArc')[0].childNodes[0].nodeValue);

											let lastPoint = null;
											if(i < avxList.length - 1) {
												let nextAvx = avxList[i + 1];
												let nextLat = this.parseGeoLat(nextAvx.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
												let nextLng = this.parseGeoLon(nextAvx.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
												lastPoint = [nextLat, nextLng];
											}

											this.makeCircleCWA([latCenter, lngCenter], [lat, lng], lastPoint, 32, airspace.coordinates);

											break;
										}
										case 'CCA': {
											let latCenter = this.parseGeoLat(avx.getElementsByTagName('geoLatArc')[0].childNodes[0].nodeValue);
											let lngCenter = this.parseGeoLon(avx.getElementsByTagName('geoLongArc')[0].childNodes[0].nodeValue);

											let lastPoint = null;
											if(i < avxList.length - 1) {
												let nextAvx = avxList[i + 1];
												let nextLat = this.parseGeoLat(nextAvx.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
												let nextLng = this.parseGeoLon(nextAvx.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
												lastPoint = [nextLat, nextLng];
											}

											this.makeCircleCCA([latCenter, lngCenter], [lat, lng], lastPoint, 32, airspace.coordinates);
											break;
										}
										case 'RHL': {
											airspace.coordinates.push([lat, lng]);
											console.log('RHL (rhumb line) <Avx> codeType might need more testing.');
											break;
										}
										default: {
											console.log('Unhandled <Avx> codeType: ' + codeType);
											break;
										}
									}
								}

							}
						}

					}
				}

				let result = { };

				result.airspaceTypes = ['BORDER'];
				for(let type in this.airspaceTypeDataDefault) {
					let data = this.airspaceTypeDataDefault[type];
					if(data.canBeActivated) {
						result.airspaceTypes.push(type);
					}
				}
				for(let type in airspaceTypesMap) {
					result.airspaceTypes.push(type);
				}

				result.airspaceTypes.sort((t1, t2) => {
					let w1 = this.airspaceTypeData[t1];
					let w2 = this.airspaceTypeData[t2];
					if(w1) w1 = w1.weight; else w1 = 100;
					if(w2) w2 = w2.weight; else w2 = 100;
					return w1 - w2;
				});

				for(let airspaceId in airspaces) {
					let airspace = airspaces[airspaceId];
					if(airspace.coordinates.length > 0) {
						if(airspace.type == 'BORDER') {
							this.makePolyline(airspace);
						}
						else {
							this.makePolygon(airspace);
						}
					}
				}

				this.airspaceTypes = result.airspaceTypes;

				if(!this.maxElevFilterSet) {
					this.maxElevFilter = Math.min(this.maxElev, 9000);
				}

				this.polygons.sort((p1, p2) => p2.area - p1.area);

				for(let polygon of this.polygons) {
					let data = this.airspaceTypeData[polygon.airspace.type];
					if(data.enabled) {
						polygon.addTo(this.map);
					}
				}

				resolve(result);
			};
			oReq.open('GET', filename);
			oReq.send();
		});
	};

	this.loadNewAirspaces = async () => {
		const airspaces = await this.fetchNewAirspaces();
		this.clear();
		this.polygons = [];
		this.airspaceTypes = [];
		await this.loadTempAirspaces();
		for(let a of airspaces) {
			// a is an airspace polygon object
			this.makePolygon(a);

			// an airspace polygon object has a types array that includes all the different types of airspaces that are in that polygon.
			// For example there is an airspace polygon that has a type of "CTR" and a type of "TMA" with different heights and different names.
			for (const type of a.types) {

				// if the airspace type is "LARA_FUA" and it is not active, then change the type to "PUBLISHED"
				// this is because there are some airspaces that are "LARA_FUA" but they are not active and they should be treated as "PUBLISHED"
				if(type.type == "LARA_FUA") {
					if(!type.isActive) {
						type.type = "PUBLISHED";
					}
				}

				if(!this.airspaceTypes.includes(type.type)) {
					this.airspaceTypes.push(type.type);
				}

				if(type.heightFrom > this.maxElev) {
					this.maxElev = type.heightFrom;
				}
			}
		}

		this.airspaceTypes.sort((t1, t2) => {
			let w1 = this.airspaceTypeData[t1];
			let w2 = this.airspaceTypeData[t2];
			if(w1) w1 = w1.weight; else w1 = 100;
			if(w2) w2 = w2.weight; else w2 = 100;
			return w1 - w2;
		});

		if(!this.maxElevFilterSet) {
			this.maxElevFilter = Math.min(this.maxElev, 9000);
		}

		this.polygons.sort((p1, p2) => p2.area - p1.area);

		for(let polygon of this.polygons) {
				polygon.addTo(this.map);
		}
	}

	this.extendAlongBorder = function(airspace, startCoordinate, frontierName, avxList, endCoordinate, i) {
		let firstPoint = this.findIndexOfNearestFrontierPoint(frontierName, startCoordinate);
		if(firstPoint) {

			let lastPoint;
			let forward;

			if(avxList) {
				let nextAvx;
				if(i < avxList.length - 1) {
					nextAvx = avxList[i + 1];
				}
				else {
					nextAvx = avxList[0];
				}

				let nextLat = this.parseGeoLat(nextAvx.getElementsByTagName('geoLat')[0].childNodes[0].nodeValue);
				let nextLng = this.parseGeoLon(nextAvx.getElementsByTagName('geoLong')[0].childNodes[0].nodeValue);
				lastPoint = this.findIndexOfNearestFrontierPoint(frontierName, [nextLat, nextLng]);

				forward = lastPoint.index > firstPoint.index;
			}
			else if(endCoordinate) {
				lastPoint = this.findIndexOfNearestFrontierPoint(frontierName, endCoordinate);

				forward = lastPoint.index > firstPoint.index;

				if(firstPoint.index > lastPoint.index) {
					let d1 = firstPoint.frontierCoordinates.length - firstPoint.index + lastPoint.index;
					let d2 = firstPoint.index - lastPoint.index;
					if(d1 < d2) {
						forward = true;
					}
				}
				else {
					let d1 = lastPoint.frontierCoordinates.length - lastPoint.index + firstPoint.index;
					let d2 = lastPoint.index - firstPoint.index;
					if(d1 < d2) {
						forward = false;
					}
				}
			}
			else {
				return;
			}



			let start = firstPoint.index;
			let end = lastPoint.index;
			let safety = false;

			if(forward) {
				if(firstPoint.distance == 0) {
					start++;
				}
				for(let index = start; ; index++) {
					if(index == firstPoint.frontierCoordinates.length) {
						index = 0;
						if(safety) {
							break;
						}
						else {
							safety = true;
						}
					}
					let c = firstPoint.frontierCoordinates[index];
					airspace.coordinates.push(c);
					if(index == end) {
						break;
					}
				}
			}
			else {
				if(firstPoint.distance == 0) {
					start--;
				}
				for(let index = start; ; index--) {
					if(index == -1) {
						index = firstPoint.frontierCoordinates.length - 1;
						if(safety) {
							break;
						}
						else {
							safety = true;
						}
					}
					let c = firstPoint.frontierCoordinates[index];
					airspace.coordinates.push(c);
					if(index == end) {
						break;
					}
				}
			}
		}
	};

	this.geodesicArea = function(latLngs) {
		let pointsCount = latLngs.length;
		let area = 0.0;
		let d2r = Math.PI / 180;
		let p1;
		let p2;

		if(pointsCount > 2) {
			for(let i = 0; i < pointsCount; i++) {
				p1 = latLngs[i];
				p2 = latLngs[(i + 1) % pointsCount];
				let a = ((p2.lng - p1.lng) * d2r) *
					(2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
				area += a;
			}
			area = area * 6378137.0 * 6378137.0 / 2.0;
		}

		return Math.abs(area);
    };

	this.parseGeoLat = function(geoLat) {
		if(geoLat) {
			geoLat = geoLat.trim().toUpperCase();
			if(geoLat.endsWith('N')) {
				geoLat = geoLat.substring(0, geoLat.length - 1);
				return parseFloat(geoLat);
			}
			if(geoLat.endsWith('S')) {
				geoLat = geoLat.substring(0, geoLat.length - 1);
				return -1 * parseFloat(geoLat);
			}
		}
		return 0;
	};

	this.parseGeoLon = function(geoLon) {
		if(geoLon) {
			geoLon = geoLon.trim().toUpperCase();
			if(geoLon.endsWith('E')) {
				geoLon = geoLon.substring(0, geoLon.length - 1);
				return parseFloat(geoLon);
			}
			if(geoLon.endsWith('W')) {
				geoLon = geoLon.substring(0, geoLon.length - 1);
				return -1 * parseFloat(geoLon);
			}
		}
		return 0;
	};

	this.makePolyline = function(airspace) {
		if(airspace && airspace.coordinates.length > 0) {
			let color;
			let d = this.airspaceTypeDataDefault[airspace.type];
			if(d && d.color) {
				color = d.color;
			}
			else {
				color = this.colors[this.colorIndex];
				this.colorIndex++;
				if(this.colorIndex >= this.colors.length) {
					this.colorIndex = 0;
				}
			}

			let polyline = window.L.polyline(airspace.coordinates, {
				color: airspace.color ? airspace.color : color,
				weight: 2
			});

			polyline.airspace = airspace;
			polyline.area = 9007199254740991;

			this.polygons.push(polyline);
		}
	};

	this.makePolygon = function(airspace) {
		if(airspace && (airspace.coordinates).length > 0) {
			let color;
			let d = this.airspaceTypeDataDefault[airspace.type];

			if(d && d.color) {
				color = d.color;
			}
			else {
				color = this.colors[this.colorIndex];
				this.colorIndex++;
				if(this.colorIndex >= this.colors.length) {
					this.colorIndex = 0;
				}
			}

			let maxType = null;
			if(airspace.types != null) {
				for (const type of airspace.types) {
					d = this.airspaceTypeData[type.type];
					if(!d) continue;
					if(!d.enabled) continue;
					if(type.heightFrom > this.maxElevFilter) continue;
					if(!maxType)  {
						maxType = d;
					}
					else if(maxType.weight < d.weight)  {
						maxType = d;
					}
				}

				if(maxType) {
					color = maxType.color;
				}
			}

			let polygon = window.L.polygon(airspace.coordinates, {
				color: airspace.color || color,
				fillOpacity: (maxType && maxType.filled) ? 0.4 : 0,
				weight: 2,
				dashArray: maxType && maxType.dashed ? '4, 4' : undefined,
				dashOffset: maxType && maxType.dashed ? '0' : undefined,
				interactive: true,
			});
			polygon.airspace = airspace;

			polygon.area = this.geodesicArea(polygon.getLatLngs()[0]);

			this.viewManager.tooltipManager.add(
				polygon,
				this.map,
				(obj) => { // textFN
					return this.makeTooltipText(obj);
				},
				(obj) => { // onShow
					obj.setStyle({ fillOpacity: 0.4 });
				},
				(obj) => { // onHide
					obj.setStyle({fillOpacity: (maxType && maxType.filled) ? 0.4 : 0});
				},
				(obj, latLng, text) => { // onClick
					// text = this.findFlightAreasUnderClick(obj, latLng, text);

          if (!this.viewManager.draggingMarker) {
            this.viewManager.$emit('mapClick', latLng, text);
          }

					return `<div style="overflow-y: auto; overflow-x: hidden; max-height: 500px;">${text}</div>`;
				}
			);

			this.polygons.push(polygon);
		}
	};

	this.makeFullCircle = function(center, radius, numberOfSegments) {
		let coordinates = [];

		let fullCircle = 2 * Math.PI;
		let segmentBearing = fullCircle / numberOfSegments;

		let bearing = 0;
		let end = bearing + fullCircle;

		while(bearing < end) {
			coordinates.push(this.offset(center, radius, bearing));
			bearing += segmentBearing;
		}

		return coordinates;
	};

	this.makeTooltipText = function(polygon) {
		let airspace = polygon.airspace;

		let text = '';

		if(airspace.types != null) {
			for (const type of airspace.types) {
				let data = this.airspaceTypeData[type.type];
				if(data) {
					if(!data.enabled || type.heightFrom > this.maxElevFilter) {
						continue;
					}
				}
				text += `<span class="tooltip-title mt-3">${type.name}</span>`;

				text += '<table class="table table-sm mb-0">';
				text += '<tr>';
				text += `<th>${this.viewManager.$t('planes.model')}:</th>`;
				text += `<td class="nowrap"> ${data && data.nameOverride ? data.nameOverride : type.type}</td>`;
				text += '</tr>';

				text += '<tr>';
				text += `<th>${this.viewManager.$t("flightareamanager.upper-limit")}:</th>`;
				text += `<td class="nowrap"> ${type.heightTo || '0'} FT ${type.heightToType || ''}</td>`;
				text += '</tr>';

				text += '<tr>';
				text += `<th>${this.viewManager.$t("flightareamanager.lower-limit")}:</th>`;
				text += `<td class="nowrap"> ${type.heightFrom || '0'} FT ${type.heightFromType || ''}</td>`;
				text += '</tr>';

				if(type.fromDate && type.toDate && (type.type == "LARA_FUA" || type.type == "PUBLISHED")){
					const from = window.formatDateTime(type.fromDate);
					const to = window.formatDateTime(type.toDate);

					text += '<tr>';
					text += `<th>${this.viewManager.$t("flightareamanager.valid")}:</th>`;
					text += `<td class="nowrap">${from} -<br/>${to}</td>`;
					text += '</tr>';
				}

				text += '</table>';
			}

			return text;
		}


		let d = this.airspaceTypeDataDefault[airspace.type];

		text = '<span class="tooltip-title">';
		text += airspace.name;
		text += '</span>';

		if(d.canBeActivated && airspace.active) {
			text += '<br/><b class="airspace-active">' + this.viewManager.$t('flightareamanager.active') + '</b>';
		}
		else if(d.activeType && !airspace.active) {
			text += '<br/><b class="airspace-inactive">'+ this.viewManager.$t('flightareamanager.inactive') +'</b>';
		}


		text += '<table class="table table-sm mb-0">';

		text += '<tr>';
		text += '<th>' + this.viewManager.$t('flightareamanager.type') +':</th><td class="nowrap">';
		text += d && d.nameOverride ? d.nameOverride : airspace.type;
		text += '</td>';

		text += '<tr>';
		text += '<th>' + this.viewManager.$t('flightareamanager.upper-limit') + ':</th><td>';
		if(airspace.codeDistVerUpper) {
			text += airspace.valDistVerUpper + ' ' + airspace.uomDistVerUpper + ' (' + airspace.codeDistVerUpper + ')';
		}
		else if(airspace.heightTo) {
			text += airspace.heightTo + 'FT ' + ' (' + airspace.heightToType + ')';
		}
		else {
			text += airspace.valDistVerUpper + ' ' + airspace.uomDistVerUpper;
		}
		text += '</td>';

		text += '<tr>';
		text += '<th>' + this.viewManager.$t('flightareamanager.lower-limit') + ':</th><td>';
		if(airspace.codeDistVerLower) {
			text += airspace.valDistVerLower + ' ' + airspace.uomDistVerLower + ' (' + airspace.codeDistVerLower + ')';
		}
		else if(airspace.heightFrom != null) {
			text += airspace.heightFrom + 'FT ' + ' (' + airspace.heightFromType + ')';
		}
		else {
			text += airspace.valDistVerLower + ' ' + airspace.uomDistVerLower;
		}
		text += '</td>';

		if(airspace.comment) {
			text += '<tr>';
			text += '<td colspan="2"><b>';
			text += airspace.comment;
			text += '</b></td>';
		}

		if(airspace.day) {
			text += '<tr>';
			text += '<th>' + this.viewManager.$t('flightareamanager.day') + ':</th><td>';
			text += airspace.day;
			text += '</td>';
		}

		if(airspace.startTime) {
			text += '<tr>';
			text += '<th>' + this.viewManager.$t('flightareamanager.planned-time') + ':</th><td>';
			text += airspace.startTime;
			if(airspace.endTime) {
				text += ' - ';
				text += airspace.endTime;
			}
			text += '</td>';
		}

		text += '</table>';

		return text;
	};


	this.makeCircleCWA = function(center, startC, endC, numberOfSegments, coordinates) {
		let radius = this.calcDistance(center, startC);

		let fullCircle = 2 * Math.PI;
		let segmentBearing = fullCircle / numberOfSegments;

		let bearing = this.bearing(center, startC);
		let end = bearing + fullCircle;

		if(endC) {
			let endBearing = this.bearing(center, endC);
			if(endBearing < end) {
				end = endBearing;
			}
		}

		while(bearing < end) {
			coordinates.push(this.offset(center, radius, bearing));
			bearing += segmentBearing;
		}

		if(endC) {
			coordinates[coordinates.length - 1] = endC;
		}
	};

	this.makeCircleCCA = function(center, startC, endC, numberOfSegments, coordinates) {
		let radius = this.calcDistance(center, startC);

		let fullCircle = 2 * Math.PI;
		let segmentBearing = fullCircle / numberOfSegments;

		let bearing = this.bearing(center, startC);
		let end = bearing - fullCircle;

		if(endC) {
			let endBearing = this.bearing(center, endC);
			if(endBearing > end) {
				end = endBearing;
			}
		}

		while(bearing > end) {
			coordinates.push(this.offset(center, radius, bearing));
			bearing -= segmentBearing;
		}

		if(endC) {
			coordinates[coordinates.length - 1] = endC;
		}
	};

	this.offset = function(center, radius, bearing) {
		function fmod(a, b) {
			return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
		}

		let rad = Math.PI / 180;

		let lat1 = center[0] * rad;
		let lon1 = center[1] * rad;

		let dByR = radius / 6378137;

		let lat = Math.asin(
			Math.sin(lat1) * Math.cos(dByR) +
			Math.cos(lat1) * Math.sin(dByR) * Math.cos(bearing)
		);

		let lon = lon1 + Math.atan2(
			Math.sin(bearing) * Math.sin(dByR) * Math.cos(lat1),
			Math.cos(dByR) - Math.sin(lat1) * Math.sin(lat)
		);

		lon = fmod(lon + 3 * Math.PI, 2 * Math.PI) - Math.PI;

		return [lat / rad, lon / rad];
	};

	this.bearing = function (start, end) {
		let rad = Math.PI / 180;

		let startLat = start[0] * rad;
		let startLong = start[1] * rad;
		let endLat = end[0] * rad;
		let endLong = end[1] * rad;

		let dLong = endLong - startLong;

		let dPhi = Math.log(Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0));

		if(Math.abs(dLong) > Math.PI) {
			if(dLong > 0.0) {
				dLong = -(2.0 * Math.PI - dLong);
			}
			else {
				dLong = (2.0 * Math.PI + dLong);
			}
		}

		let fullCircle = Math.PI * 2;

		return (Math.atan2(dLong, dPhi) + fullCircle) % fullCircle;
	}

	this.calcDistance = function(p1, p2) {
		let rad = Math.PI / 180;
		let nauticalMile = 1852;
		let lon1 = rad * -p1[1];
		let lat1 = rad * p1[0];
		let lon2 = rad * -p2[1];
		let lat2 = rad * p2[0];
		let d = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin((lat1 - lat2) / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin((lon1 - lon2) / 2), 2)));
		return nauticalMile * 60 * d / rad;
	};

	this.findIndexOfNearestFrontierPoint = function(frontierName, point) {
		let coordinates;
		if(frontierName) {
			coordinates = this.frontiers[frontierName];
		}
		else {
			coordinates = this.hungaryFrontier;
		}

		let mini = -1;
		let minDistance = 100000000;
		let minC = null;
		for(let i = 0; i < coordinates.length; i++) {
			let c = coordinates[i];
			let d = this.calcDistance(point, c);
			if(d < minDistance) {
				mini = i;
				minDistance = d;
				minC = c;
			}
		}
		if(mini == -1) {
			return null;
		}
		return {
			index: mini,
			coordinate: minC,
			distance: minDistance,
			frontierCoordinates: coordinates
		};
	};

	this.toggleAirspacesByType = function(type, on) {
		let data = this.airspaceTypeData[type];
		if(data) {
			data.enabled = on;
		}

		let d = {};
		for(let _type in this.airspaceTypeData) {
			d[_type] = this.airspaceTypeData[_type].enabled;
		}
		window.consentedStorage.setItem('airspaceTypeData', JSON.stringify(d));
	};

  this.loadSettings = function (){
    this.toggleAirspacesByElevation(this.maxElevFilter);
    this.toggleAirspaces();
  }

	this.toggleAirspacesByElevation = function(elev) {
		this.maxElevFilter = elev;
		window.consentedStorage.setItem('maxElevFilter', '' + this.maxElevFilter);
	};

	this.toggleAirspaces = function() {
		for(let polygon of this.polygons) {

				let maxType = null;
				if(polygon.airspace.types != null) {
					for (const type of polygon.airspace.types) {
						let d = this.airspaceTypeData[type.type];
						if(!d) continue;
						if(!d.enabled) continue;
						if(type.heightFrom > this.maxElevFilter) continue;
						if(!maxType)  {
							maxType = d;
						}
						else if(maxType.weight < d.weight)  {
							maxType = d;
						}
					}


				if(maxType){
					polygon.setStyle({
						color: maxType.color,
						fillOpacity: (maxType && maxType.filled) ? 0.4 : 0,
						weight: 2,
						dashArray: maxType && maxType.dashed ? '4, 4' : undefined,
						dashOffset: maxType && maxType.dashed ? '0' : undefined,
						interactive: true
					});
					polygon.addTo(this.map);
					if(polygon.airspace.type == "TEMP-ACTIVE") {
						polygon.bringToBack();
					}else{
						polygon.bringToFront();
					}

				}else{
					polygon.remove();
				}

				continue;

			}

			let data = this.airspaceTypeData[polygon.airspace.type];
			if(data && data.enabled && (polygon.airspace.lowerInFeet <= this.maxElevFilter || polygon.airspace.type == 'BORDER')) {
				polygon.addTo(this.map);
			}
			else if(!data) {
				polygon.addTo(this.map);
			}
			else {
				polygon.remove();
			}
		}
	};

  this.clear = function() {
		for(let polygon of this.polygons) {
			polygon.remove();
		}
	};

}
