import $ from 'jquery'

export function TooltipManager(viewManager, enableTooltips) {

	this.viewManager = viewManager;
	this.enableTooltips = enableTooltips;

	this.tooltip = window.L.tooltip({
		sticky: true,
		permanent: true,
		interactive: true,
		className: 'map-tooltip',
		offset: [10, 0]
	});

	this.currentObj = null;

	this.hideCurrentObj = function(floating) {
		if(this.currentObj) {
			if((!floating || !this.currentObj.onlyClickShowHideEvents) && typeof this.currentObj.onHide === 'function') {
				this.currentObj.onHide(this.currentObj);
			}
			this.currentObj = null;
		}
	};

	this.makeTooltipText = function(textFN, obj, hasCloseButton, forTooltip, width = '150px') {
		let text = '';
		//if(forTooltip) {
			text += `<div style="font-size: 0.8em;white-space: normal; min-width: ${width};">`;
		//}

		if(hasCloseButton) {
			text += `<button id="closeTooltipButton" class="btn btn-danger btn-sm color-white tooltip-close">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
			</button>`;
		}

		if(typeof textFN === 'function') {
			text += textFN(obj);
		}

		//if(forTooltip) {
			text += '</div>';
		//}

		return text;
	}

	this.refreshTooltip = function() {
		if(this.tooltip && this.tooltip.obj) {
			this.tooltip.obj.refeshTooltip();
		}
	};

	this.add = function(obj, map, textFN, onShow, onHide, onClick, onlyClick, onlyClickShowHideEvents, width) {
		let self = this;

		obj.onHide = onHide;
		obj.onlyClickShowHideEvents = onlyClickShowHideEvents;

		obj.refeshTooltip = () => {
			if(obj == self.tooltip.obj) {
				self.tooltip.setContent(self.makeTooltipText(textFN, obj, obj.tooltipOpened, obj.tooltipOpened, width));
				addCloseEventHandler(obj, !obj.tooltipOpened);
				if(typeof obj.getLatLng === 'function') {
					self.tooltip.setLatLng(obj.getLatLng());
				}
			}
		};

		let upHandler = function(obj, floating) {
			self.tooltip.setContent('');
			self.tooltip.remove();
			obj.tooltipOpened = false;

			self.hideCurrentObj(floating);
		};

		let addCloseEventHandler = function(obj, floating) {
			$('#closeTooltipButton').on('click', () => {
				self.tooltip.setContent('');
				self.tooltip.remove();
				obj.tooltipOpened = false;

				self.hideCurrentObj(floating);
			});
		};

		let downHandler = function(obj, e, floating, text = null) {
			if(self.viewManager.showCurrentGPS) return;
			if(self.tooltip.obj && obj !== self.tooltip.obj) {
				self.tooltip.obj.tooltipOpened = false;
				self.tooltip.obj = null;
			}
			if(obj.tooltipOpened) {
				upHandler(obj, floating);
			}
			else {
				self.tooltip.setContent(text ? text : self.makeTooltipText(textFN, obj, !floating, !floating, width));
				self.tooltip.setLatLng(e.latlng);
				self.tooltip.addTo(map);
				self.tooltip.obj = obj;
				if(!floating) {
					obj.tooltipOpened = true;
				}

				addCloseEventHandler(obj, floating);

				self.hideCurrentObj(floating);
				if(!floating || !onlyClickShowHideEvents) {
					self.currentObj = obj;
					if(typeof onShow === 'function') {
						onShow(obj);
					}
				}
			}
		};

		if(!onlyClick) {
			obj.on('mouseover', function(e) {
				if(!self.tooltip.obj || !self.tooltip.obj.tooltipOpened) {
					self.hideCurrentObj(true);
					downHandler(this, e, true);
				}
				if(!onlyClickShowHideEvents && typeof onShow === 'function') {
					onShow(this);
				}
			});

			obj.on('mousemove', function(e) {
				if(!this.tooltipOpened && self.tooltip.obj && this === self.tooltip.obj) {
					self.tooltip.setLatLng(e.latlng);
				}
			});

			obj.on('mouseout', function(e) {
				if(!self.tooltip.obj || !self.tooltip.obj.tooltipOpened) {
          self.hideCurrentObj(true);
				}
				if(!this.tooltipOpened && self.tooltip.obj && this === self.tooltip.obj) {
					upHandler(this, true);
				}
				else if(!this.tooltipOpened && (!self.tooltip.obj || this !== self.tooltip.obj)) {
					if(!onlyClickShowHideEvents && typeof onHide === 'function') {
						onHide(this);
					}
				}
			});
		}

		obj.on('click', function(e) {
			let text = null;
			if(typeof onClick === 'function') {
				text = onClick(obj, e.latlng, self.makeTooltipText(textFN, obj, false, false, width));
			}
			if(self.enableTooltips) {
				downHandler(this, e, false, text);
			}

			window.L.DomEvent.stopPropagation(e);
		});
	};

}
