export function AirportManager(map, rest, viewManager) {
	this.map = map;
  this.rest = rest;
	this.viewManager = viewManager;
  this.airports = [];
  this.markers = [];


  this.getIconForAirport = function (airport){

    let iconUrl = require('@/assets/airport_solid.png');

    let defaultRunway = airport.airportRunways[0];
    if(defaultRunway){
      if(["GRASS", "SAND"].includes(defaultRunway.type )) {
        iconUrl = require('@/assets/airport_transparent.png');
      }
    }


    const icon = window.L.icon({
      iconUrl: iconUrl,
      iconSize: [20, 20],
      iconAnchor: [10, 10],
    });
    return icon;
  }

  this.updateMarkers = function () {
    let z = this.viewManager.map.getZoom();
    this.markers.forEach(marker => {
      let icon = marker.options.icon;

      let iconsize = 10

      if(z > 10) {
        iconsize = 20;
      }else if(z > 8) {
        iconsize = 16;
      }else  if(z > 6) {
        iconsize = 12;
      }

      if(z > 10) {
        marker.openTooltip();
      }else{
        marker.closeTooltip();
      }

      icon.options.iconSize = [iconsize, iconsize];
      marker.setIcon(icon);
    })
  }

  this.makeTooltipContent = function (airport) {
    let defaultRunway = airport.airportRunways[0];
    const table = [
      {
        key: `${this.viewManager.$t('airport.frequency')}:`,
        value: `${airport.radioFrequency} Mhz`,
      },
      {
        key: this.viewManager.$t('airport.runway') + ':',
        value: `${(defaultRunway || {}).name} (${(defaultRunway || {}).trueDirection}°/${(defaultRunway || {}).oppositeTrueDirection}°) </br> ${this.viewManager.$t('runwayType.' + (defaultRunway || {}).type)}`,
      },
      // {
      //   key: this.viewManager.$t('airport.runways') + ':',
      //   value: airport.airportRunways.length + " db",
      // },
      {
        key: this.viewManager.$t('airport.elevation') + ':',
        value: `${airport.elevation} ft`,
      },
    ];

    return `
      <span class="tooltip-title">
        ${airport.name} (${airport.code})
      </span>
      <table class="table table-sm mb-0">
        ${table.map((i) => {
          return `<tr>
              <th>${i.key}</th>
              <td class="nowrap">
                ${i.value}
              </td>
            </tr>`;
        }).join('\n')}
      </table>
    `
  }

	this.loadAirports = async function() {
		const airports = await this.rest.getAirports();
		this.airports = airports || [];
	}

	this.toggleAirports = function(on) {
    if(this.markers.length == 0) {
      this.loadMarkers();
    }

    this.markers.forEach(marker => {
      if(on){
        marker.addTo(this.map)
      }else{
        marker.remove()
      }
    })

    this.updateMarkers();
		window.consentedStorage.setItem('toggleAirports', on ? "1": "0");
	};

  this.loadMarkers = function(){
    (this.airports || []).forEach(airport => {
      let marker = window.L.marker([airport.latitude, airport.longitude], {
        rotateWithView: true,
        icon: this.getIconForAirport(airport),
      });

      let defaultRunway = airport.airportRunways[0];
      if(defaultRunway){
        marker.setRotation(defaultRunway.trueDirection * (Math.PI / 180))
      }


      marker.bindTooltip(airport.code, {
        direction: "center",
        permanent: true,
        className: 'name-tooltip',
        offset: [0, 20]
      })

      this.viewManager.tooltipManager.add(
				marker,
				this.map,
				(obj) => { // textFN
					return this.makeTooltipContent(airport);
				},
				(obj) => { // onShow
				},
				(obj) => { // onHide
				},
				(obj, latLng, text) => { // onClick

          if (!this.viewManager.draggingMarker) {
            this.viewManager.$emit('mapClick', latLng, text);
          }
				}
			);


      marker.addTo(this.map);
      // marker._icon.classList.add("huechange");
      this.markers.push(marker);
    });
  }

  this.loadSettings = function () {
    this.toggleAirports(["1", null].includes(window.consentedStorage.getItem('toggleAirports')))
  }
}
