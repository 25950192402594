
function toRad(Value)
{
    return Value * Math.PI / 180;
}


/** Calculates the distance between two points on earth.
 * @param {number} lat1
 * @param {number} lon1
 * @param {number} lat2
 * @param {number} lon2
 * @returns {number} Distance in km
 */
export function calcDistance(lat1, lon1, lat2, lon2)
{
  const R = 6371; // km
  const dLat = toRad(lat2-lat1);
  const dLon = toRad(lon2-lon1);
	lat1 = toRad(lat1);
	lat2 = toRad(lat2);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	return R * c;
}




export function Calculator(viewManager) {

	this.speedFromKmH = function(speedInKmPerHours) {
		if(speedInKmPerHours instanceof String) {

			speedInKmPerHours = parseFloat(speedInKmPerHours);
		}
		let speedUnit = window.consentedStorage.getItem('speedUnit');
		if(!speedUnit || speedUnit === 'kmh') {
			return speedInKmPerHours.toFixed(0) + ' km/h';
		}
		if(speedUnit === 'kt') {
			return (speedInKmPerHours / 1.852).toFixed(1) + ' ' + viewManager.$t('menu.units-speed-unit-knot');
		}
		if(speedUnit === 'ms') {
			return (speedInKmPerHours / 3.6).toFixed(1) + ' m/s';
		}
		if(speedUnit === 'mph') {
			return (speedInKmPerHours / 1.60934).toFixed(0) + ' mp/h';
		}
		return '';
	};

	this.elevationFromFeet = function(elevationInFeet) {
		if(elevationInFeet instanceof String) {
			elevationInFeet = parseFloat(elevationInFeet);
		}
		let elevationUnit = window.consentedStorage.getItem('elevationUnit');
		if(!elevationUnit || elevationUnit === 'ft') {
			return elevationInFeet.toFixed(0) + ' ft';
		}
		if(elevationUnit === 'm') {
			return (elevationInFeet * 0.3048).toFixed(0) + ' m';
		}
		return '';
	};

	this.elevationFromMeter = function(elevationInMeters) {
		if(elevationInMeters instanceof String) {
			elevationInMeters = parseFloat(elevationInMeters);
		}
		let elevationUnit = window.consentedStorage.getItem('elevationUnit');
		if(!elevationUnit || elevationUnit === 'ft') {
			return (elevationInMeters / 0.3048).toFixed(0) + ' ft';
		}
		if(elevationUnit === 'm') {
			return elevationInMeters.toFixed(0) + ' m';
		}

		return '';
	};

	this.distanceFromKm = function(distanceInKm, toFixed = 2) {
		distanceInKm = parseFloat(distanceInKm);
		if(isNaN(distanceInKm)) {
			distanceInKm = 0;
		}

		let distanceUnit = window.consentedStorage.getItem('distanceUnit');
		if(!distanceUnit || distanceUnit === 'km') {
			return (distanceInKm || 0).toFixed(toFixed) + ' km';
		}
		if(distanceUnit === 'nm') {
			return (distanceInKm / 1.852).toFixed(toFixed) + ' nm';
		}
		return '';
	};

	this.directionFromTrue = function(trueDirection) {
		if(trueDirection instanceof String) {
			trueDirection = parseFloat(trueDirection);
		}
		let directionUnit = window.consentedStorage.getItem('directionUnit');
		if(directionUnit == 'true') {
			return trueDirection.toFixed(0) + '° tr.';
		}

		if(!directionUnit || directionUnit == 'magnetic') {
			let declination = parseFloat(window.consentedStorage.getItem('declination'));
			if(isNaN(declination)) {
				declination = 5;
			}
			return (Math.max(trueDirection - declination, 0)).toFixed(0)  + '° m.tr';
		}
		return '';
	};

}
