<template>
  <div class="mx-2 py-1 d-flex">
		<button class="btn btn-primary text-wrap" @click="data.event()">
			<font-awesome-icon :icon="data.icon"/>
			{{ data.title}}
		</button>
  </div>
</template>

<script>
export default {
  name: "ButtonElement",
  props: ['data'],
  data() {
    return {
      id: null
    };
  },
  created() {
    this.id = this._uid;
  }
}
</script>

<style scoped>

</style>
