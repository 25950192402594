import RightMenuElement from "@/components/menu/menuComponent/RightMenuElement";
import CheckboxElement from "@/components/menu/menuComponent/CheckboxElement";
import RadioElements from "@/components/menu/menuComponent/RadioElements";
import InputNumberElement from "@/components/menu/menuComponent/InputNumberElement";
import InputTrueAltitudeElement from "@/components/menu/menuComponent/InputTrueAltitudeElement";
import Vue from "vue";
import { store } from "../store/store";
import { mapGetters } from "vuex";
import ButtonElement from "@/components/menu/menuComponent/ButtonElement.vue";

export function MenuManager(viewManager) {

	this.viewManager = viewManager;


	/**
	 * @param {Array<{component: Vue, props: Object}>} components
	 * @param {boolean} addBackMenuItem
	 * @param {Function|undefined} fnBack
	 * @param {string|undefined} help
	 *
	**/
	this.setMenu = function(components, addBackMenuItem, fnBack, help) {
		if(addBackMenuItem) {
			components.splice(0, 0, {
				component: RightMenuElement,
				props: {
					title: 'back', icon: 'chevron-left',
					help: help,
          event: () => {
						if(fnBack) {
							return fnBack();
						}
						return this.loadMainMenu();
					}
				}
			});
		}
		Vue.set(Vue.prototype, '$rightMenu', components);
	};

	this.loadMainMenu = function() {
		this.setMenu([
			{
				component: RightMenuElement,
				props: {
					title: 'airspaceSettings', icon: 'layer-group', event: () => {
						return this.loadAirspaceMenu();
					}
				}
			},
			{
				component: RightMenuElement,
				props: {
					title: 'mapSettings', icon: ['far', 'map'], event: () => {
						return this.loadMapSettingsMenu();
					}
				}
			},
			{
				component: RightMenuElement,
				props: {
					title: 'trafficSettings', icon: 'plane', event: () => {
						return this.loadTrafficSettingsMenu();
					}
				}
			},
			{
				component: RightMenuElement,
				props: {
					title: 'unitSettings', icon: 'ruler', event: () => {
						return this.loadUnitSettingsMenu();
					},
				}
			},
      ...(window.platform != "android" ? [] : [{
        // Hacky solution but I didnt want to update the whole menu every time a location data comes in - Geri
				component: InputTrueAltitudeElement,
				props: {
					title:this.viewManager.$t('settings.trueAltitude'),
          event: (e) => {
						// This is handled by the component
					}
				}
			}])
		], false);
	};

	this.loadAirspaceMenu = function(fnBack) {
		this.setMenu(this.viewManager.flightAreaManager.makeRightMenuComponents(), true, fnBack, "airspace-settings");
	};

	this.loadMapSettingsMenu = function(fnBack) {
		this.setMenu([
			...(!this.viewManager.showCurrentGPS ? [] : [{
				component: CheckboxElement,
				props: {
					title: this.viewManager.$t('menu.rotate-map'),
					value: 'rotateMap',
					checked: this.viewManager.rotateMap,
					event: (type, on) => {
						this.viewManager.toggleRotateMap(on, true);
					}
				}
			}]),
			{
				component: CheckboxElement,
				props: {
					title: this.viewManager.$t('menu.show-airports'),
					value: 'showAirports',
					checked:  ["1", null].includes(window.consentedStorage.getItem('toggleAirports')),
					event: (type, on) => {
						this.viewManager.airportManager.toggleAirports(on);
					}
				}
			},
			{
				component: CheckboxElement,
				props: {
					title: this.viewManager.$t('menu.show-navpoints'),
					value: 'showNavPoints',
					checked:  ["1", null].includes(window.consentedStorage.getItem('toggleNavPoints')),
					event: (type, on) => {
						this.viewManager.navpointManager.toggleNavPoints(on);
					}
				}
			},
			{
				component: RadioElements,
				props: {
					title: this.viewManager.$t('menu.map-layers'),
					items: this.viewManager.mapLayerManager.buildListForRadios(),
					defaultValue: this.viewManager.mapLayerManager.mapLayer,
					event: (value) => {
						this.viewManager.mapLayerManager.mapLayer = value;
						this.viewManager.mapLayerManager.swapLayers(true);
					}
				}
			},
			...(window.platform === 'web' ? [] : [{
				component: ButtonElement,
				props: {
					title: this.viewManager.$t('pageTitle.offline-layers'),
					icon: 'external-link-alt',
					event: (value) => {
						this.viewManager.$router.push({ name: 'offline-layers' });
					}
				}
			}]),
			{
				component: InputNumberElement,
				props: {
					title: this.viewManager.$t('menu.min-zoom'),
					default: this.viewManager.minZoom,
					min: 1,
					max: 19,
					event: (value) => {
						this.viewManager.minZoom = value;
					}
				}
			},
			{
				component: InputNumberElement,
				props: {
					title: this.viewManager.$t('menu.max-zoom'),
					default: this.viewManager.maxZoom,
					min: 1,
					max: 19,
					event: (value) => {
						this.viewManager.maxZoom = value;
					}
				}
			}
		], true, fnBack, "map-settings");
	};

	this.loadTrafficSettingsMenu = function(fnBack) {
		this.setMenu(this.viewManager.flyingObjectManager.makeRightMenuComponents(), true, fnBack, "traffic-settings");
	};

	this.loadUnitSettingsMenu = function() {
		this.setMenu([
			{
				component: RadioElements,
				props: {
					title: this.viewManager.$t('menu.units-speed-header') + ':',
					items: [
						{ title: this.viewManager.$t('menu.units-speed-unit-kmh') + ':', value: 'kmh' },
						{ title: this.viewManager.$t('menu.units-speed-unit-knot'), value: 'kt' },
						{ title: this.viewManager.$t('menu.units-speed-unit-ms'), value: 'ms' },
						{ title: this.viewManager.$t('menu.units-speed-unit-mph'), value: 'mph' },
					],
					defaultValue: window.consentedStorage.getItem('speedUnit') || 'kmh',
					event: (value) => {
						window.consentedStorage.setItem('speedUnit', value);
						this.viewManager.tooltipManager.refreshTooltip();
						this.viewManager.refreshElevationChart();
					}
				}
			},
			{
				component: RadioElements,
				props: {
					title: this.viewManager.$t('menu.units-elevation-header') + ':',
					items: [
						{ title: this.viewManager.$t('menu.units-elevation-unit-ft'), value: 'ft' },
						{ title: this.viewManager.$t('menu.units-elevation-unit-m'), value: 'm' },
					],
					defaultValue: window.consentedStorage.getItem('elevationUnit') || 'ft',
					event: (value) => {
						window.consentedStorage.setItem('elevationUnit', value);
						this.viewManager.tooltipManager.refreshTooltip();
						this.viewManager.refreshElevationChart();
					}
				}
			},
			{
				component: RadioElements,
				props: {
					title: this.viewManager.$t('menu.units-distance-header') + ':',
					items: [
						{ title: this.viewManager.$t('menu.units-distance-unit-km'), value: 'km' },
						{ title: this.viewManager.$t('menu.units-distance-unit-nm'), value: 'nm' },
					],
					defaultValue: window.consentedStorage.getItem('distanceUnit') || 'km',
					event: (value) => {
						window.consentedStorage.setItem('distanceUnit', value);
						this.viewManager.tooltipManager.refreshTooltip();
						this.viewManager.refreshElevationChart();
					}
				}
			},
      ...(!this.viewManager.showCurrentGPS ? [] : [
        {
          component: RadioElements,
          props: {
            title: this.viewManager.$t('menu.units-direction-header') + ':',
            items: [
              { title: this.viewManager.$t('menu.units-distance-direction-true'), value: 'true' },
              { title: this.viewManager.$t('menu.units-distance-direction-magnetic'), value: 'magnetic' },
            ],
            defaultValue: window.consentedStorage.getItem('directionUnit') || 'magnetic',
            event: (value) => {
              window.consentedStorage.setItem('directionUnit', value);
              this.viewManager.tooltipManager.refreshTooltip();
            }
          }
        },
        {
          component: InputNumberElement,
          props: {
            title: this.viewManager.$t('menu.units-declination-header') + ':',
            default: parseInt(window.consentedStorage.getItem('declination')) || 5,
            min: -180,
            max: 180,
            unit: '°',
            event: (value) => {
              window.consentedStorage.setItem('declination', value);
              this.viewManager.tooltipManager.refreshTooltip();
            }
          }
        },
      ]),
		], true, undefined, "unit-settings");
	};

}
