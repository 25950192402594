<template>
  <div>
    <div class="mx-2 font-weight-bold mt-3" v-if="data.title">{{ data.title }}</div>
    <div class="mx-2 d-flex" v-for="item in data.items" :key="item.value">
      <div class="mr-2">
        <input :id="'menuradios:' + id + ':' + item.value" :name="'menuradios:' + id" class="form-check-input" type="radio" :value="item.value" v-model="picked" @change="data.event(item.value)">
      </div>
      <label :for="'menuradios:' + id + ':' + item.value" class="form-check-label align-self-center">{{ item.title }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioElements",
  props: ['data'],
  data() {
    return {
      id: null,
      picked: null,
    };
  },
  created() {
    this.id = this._uid;
    this.picked = this.data.defaultValue;
  }
}
</script>

<style scoped>

</style>
