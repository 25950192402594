<template>
  <div class="py-2">
    <div class="mx-2 font-weight-bold mb-3" v-if="data.title">{{ data.title }}</div>
    <div class="mx-4">
      <Slider v-model="value" :min="data.min" :max="data.max" :step="data.step" @change="data.event(value)" />
    </div>
    <div class="mx-1 mt-2 d-flex">
      <div class="ml-0 mr-auto">{{ data.min }} {{ data.unit }}</div>
      <div class="mx-auto px-2 bg-light" style="border: 1px solid #999; border-radius: 4px;">{{ value }} {{ data.unit }}</div>
      <div class="ml-auto mr-0">{{ data.max }} {{ data.unit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderElement",
  props: ['data'],
  data() {
    return {
      value: 0
    };
  },
  created() {
    if(this.data.default !== undefined) {
      this.value = Math.min(this.data.default, this.data.max);
    }
    else {
      this.value = this.data.max;
    }
  }
}
</script>

<style scoped>

</style>
