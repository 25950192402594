<template>
	<div class="elevation-chart" v-show="showChart">
    <div class="chart-container" >
      <canvas id="elevation_chart_canvas"></canvas>
    </div>
    <div class="chart-close btn btn-danger" @click="hide">
      <font-awesome-icon icon="times" class="m-0" />
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-date-fns";
import units from "@/plugins/units";
import moment from "moment";

Chart.register(zoomPlugin);

export default {
	name: "ElevationChart",
  emits: ['close', 'dashData'],
	data() {
		return {
			/**
			 * @type {Chart}
			 */
			elevationChart: null,

			/**
			 * @type {Coordinate[]}
			 */
			coordinates: null,

			map: null,
			showChart: false,
			/** @type {number[]} */
			elapsedTime: [],
			/** @type {number[]} */
			elapsedDistance: [],

			crosshair: null,
		}
	},
	methods: {
		formatMs(ms, full = false) {
			ms = Math.round(ms);

			function padLeft(v) {
				v = '' + v;
				while(v.length < 2) {
					v = '0' + v;
				}
				return v;
			}

			let hours = Math.floor(ms / 3600000);
			ms = ms - (hours * 3600000);

			let minutes = Math.floor(ms / 60000);
			ms = ms - (minutes * 60000);

			let seconds = Math.floor(ms / 1000);
			ms = ms - (seconds * 1000);

			if(full) {
				return hours + ":" + padLeft(minutes) + ":" + padLeft(seconds);
			}

			return hours + ":" + padLeft(minutes);
		},

		calculateElapsed() {
			for (let i = 0; i < this.coordinates.length; i++) {
				let c = this.coordinates[i];
				let prev = i === 0 ? null : this.coordinates[i - 1];
				if(!prev) {
					this.elapsedTime.push(0);
					this.elapsedDistance.push(0);
					continue;
				}

				let time = moment(c.time).diff(moment(prev.time), 'ms');
				let distance = this.calcDistance(c, prev);
				this.elapsedTime.push(time + this.elapsedTime[i - 1]);
				this.elapsedDistance.push(distance + this.elapsedDistance[i - 1]);
			}
		},

		calcDistance: function(p1, p2) {
			let rad = Math.PI / 180;
			let nauticalMile = 1852;
			let lon1 = rad * -p1.longitude;
			let lat1 = rad * p1.latitude;
			let lon2 = rad * -p2.longitude;
			let lat2 = rad * p2.latitude;
			let d = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin((lat1 - lat2) / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin((lon1 - lon2) / 2), 2)));

			let dInMeters = nauticalMile * 60 * d / rad;

			if(p1.altitude === undefined || p2.altitude === undefined) {
				return dInMeters;
			}

			let altD = p1.altitude - p2.altitude;
			return Math.sqrt(dInMeters * dInMeters + altD * altD);
		},

		hide() {
      this.$emit('close');

			if(this.elevationChart) {
				this.$emit('dashData', null);
				this.elevationChart.tooltip.setActiveElements([]);
			}
      this.removeCrosshair();
			this.showChart = false;
		},
		/**
		 * @param {Coordinate[]} coordinates
		 */
		show(coordinates, map) {
			this.coordinates = coordinates;
			this.map = map;
			this.showChart = true;
			this.calculateElapsed();
			if(!this.elevationChart) {
				this.initChart();
			}
		},
		addCoordinates(coordinates) {
			this.coordinates = this.coordinates.concat(coordinates);
			this.calculateElapsed();
			this.elevationChart.data.labels = this.coordinates.map(c => new Date(c.time).toISOString());
			this.elevationChart.data.datasets[0].data = this.coordinates.map(c => c.altitude);
			this.elevationChart.update();
		},
		refresh(coordinates) {
			console.log(coordinates);
			this.coordinates = coordinates;
			this.calculateElapsed();
			this.elevationChart.data.labels = this.coordinates.map(c => new Date(c.time).toISOString());
			this.elevationChart.data.datasets[0].data = this.coordinates.map(c => c.altitude);
			this.elevationChart.update();
		},

		refreshSimple() {
			this.elevationChart.update();
		},

		initChart() {
			const ctx = document.getElementById('elevation_chart_canvas');
			this.elevationChart = new Chart(ctx, {
				plugins: [{
					afterDraw: chart => {
						if(!chart) return;
						if(!chart.tooltip) return;
						if(!chart.tooltip._active) return;
						if(!chart.tooltip._active.length) return;

						let x = chart.tooltip._active[0].element.x;
						let yAxis = chart.scales.y;
						let ctx = chart.ctx;
						ctx.save();
						ctx.beginPath();
						ctx.moveTo(x, yAxis.top);
						ctx.lineTo(x, yAxis.bottom);
						ctx.lineWidth = 3;
						ctx.strokeStyle = 'rgba(255, 0, 0, 1)';
						ctx.stroke();
						ctx.restore();
					},
				}],
				type: 'line',
				data: {
					labels: this.coordinates.map(c => new Date(c.time).toISOString()),
					datasets: [
						{
							label: 'Elevation',
							data: this.coordinates.map(c => c.altitude),
							borderColor: "#17591C",
							backgroundColor: "red",
							borderWidth: 5,
							pointRadius: 0,
							pointHoverRadius: 5,
							tension: 0.4,
						}
					]
				},
				options: {
					responsive: true,
					animation: false,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							enabled: false,
							external: this.buildTooltip,
						},
						zoom: {
							limits: {
								x: {
									min: 'original',
									max: 'original'
								},
								y: {
									min: 'original',
									max: 'original'
								}
							},
							zoom: {
								wheel: {
									enabled: true,
								},
								pinch: {
									enabled: true
								},
								mode: 'x',
							}
						},
					},
					interaction: {
						mode: 'index',
						intersect: false,
					},
					scales: {
						x: {
							display: false,
						},
						y: {
							display: true,
							grid: {
								display: true,
								color: 'rgba(0, 0, 0, 0.7)',
							},
							ticks: {
								backdropColor: 'rgba(255, 255, 255, 0.7)',
								showLabelBackdrop: true,
								color: 'rgba(0, 0, 0, 1)',
								padding: 8,
								backdropPadding: 2,
								font: {
									size: 11,
								},
								callback: (value, index, values) => {
									const elevUnit = this.$units.getUnit(window.consentedStorage.getItem('elevationUnit') || 'm') || this.$units.distance.m;
									return Math.round(units.convert(value, 'm', elevUnit.key)) + ' ' + elevUnit.suffix;
								}
							},

						},
					},
					onHover: (event, chartElement) => {
						const point = this.elevationChart.getElementsAtEventForMode(event, 'index', {intersect: false}, true)[0];
						if(!point) return;
						const index = point.index;
						const coordinate = this.coordinates[index];
						if(!coordinate) return;
						this.setMarker(coordinate.latitude, coordinate.longitude)
					},
				}
			});

			this.elevationChart.draw()
		},

		/**
		 * @param {Chart} context
		 */
		buildTooltip(context) {
			// Tooltip Element
			let tooltipEl = document.getElementById('chartjs-tooltip');

			// Create element on first render
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip';
				document.body.appendChild(tooltipEl);
			}

			// Hide if no tooltip
			const tooltipModel = context.tooltip;
			if (tooltipModel.opacity === 0) {
				tooltipEl.style.opacity = 0;
				this.$emit('dashData', null);
				return;
			}



			const index = tooltipModel.dataPoints[0].dataIndex;
			const coordinate = this.coordinates[index];
			if(!coordinate) {
				tooltipEl.style.opacity = 0;
				return;
			}

			this.$emit('dashData', coordinate)

			// Set caret Position
			tooltipEl.classList.remove('above', 'below', 'no-transform');
			if (tooltipModel.yAlign) {
				tooltipEl.classList.add(tooltipModel.yAlign);
			} else {
				tooltipEl.classList.add('no-transform');
			}

			const elevUnit = this.$units.getUnit(window.consentedStorage.getItem('elevationUnit') || 'm') || this.$units.distance.m;
			const speedUnit = this.$units.getUnit(window.consentedStorage.getItem('speedUnit') || 'kmh') || this.$units.speed.kmh;
			const distanceUnit = this.$units.getUnit(window.consentedStorage.getItem('distanceUnit') || 'km') || this.$units.distance.km;

			let alt = Math.round(this.$units.convert(coordinate.altitude, 'm', elevUnit.key));
			let speed = Math.round(this.$units.convert(coordinate.speed, 'kmh', speedUnit.key));
			let timeSince = this.formatMs(this.elapsedTime[index] || 0, true);
			let time = moment(coordinate.time).utc().format('HH:mm:ss') + ' UTC';
			let distance = Math.round(this.$units.convert(this.elapsedDistance[index] || 0, 'm', distanceUnit.key));

			tooltipEl.innerHTML =
				`<table >
											<tr>
												<td>${this.$t('elevation.altitude')}:</td>
												<td>${alt} ${elevUnit.suffix}</td>
											</tr>
											<tr>
												<td>${this.$t('elevation.speed')}:</td>
												<td>${speed} ${speedUnit.suffix}</td>
											</tr>
											<tr>
												<td>${this.$t('elevation.time-since')}:</td>
												<td>${timeSince}</td>
											</tr>
											<tr>
												<td>${this.$t('elevation.time')}:</td>
												<td>${time}</td>
											</tr>
											<tr>
												<td>${this.$t('elevation.distance')}:</td>
												<td>${distance} ${distanceUnit.suffix}</td>
											</tr>
										</table>`;

			const position = context.chart.canvas.getBoundingClientRect();


			let left = position.left + window.scrollX - tooltipEl.offsetWidth + tooltipModel.caretX - 20;
			let top = position.top + window.scrollY + tooltipModel.caretY - (tooltipEl.offsetHeight / 2) - 20;

			if(left < 20) {
				left = position.left + window.scrollX + tooltipModel.caretX + 20;
			}

			tooltipEl.style.opacity = 1;
			tooltipEl.style.position = 'absolute';
			tooltipEl.style.left = left + 'px';
			tooltipEl.style.top = top + 'px';
			tooltipEl.style.zIndex = '1000';
		},

		setMarker: function (lat, lng) {
			if(!this.crosshair) {
				let crosshair = new window.L.Icon({
					iconUrl: require('@/assets/crosshair.svg'),
					iconSize: [30, 30],
					iconAnchor: [15, 15]
				});
				this.crosshair = window.L.marker([lat, lng], { icon: crosshair }).addTo(this.map);
			}
			this.crosshair.setLatLng({ lat: lat, lng: lng })
		},
		removeCrosshair: function () {
			if(this.crosshair) {
				this.map.removeLayer(this.crosshair);
				this.crosshair = null;
			}
		},
		closestCoordinateIndex(lat, lng) {
			let coordinateDifferenceTolerance = 0.009
			let smallestDifference = 1;
			let index = -1;
			for (let i = 0; i < this.coordinates.length; i++){
				let p = this.coordinates[i];
				let fixedPointLatitude = p.latitude.toFixed(6)
				let fixedPointLongitude = p.longitude.toFixed(6)
				let latitudeDifference = Math.abs(lat - fixedPointLatitude)
				let longitudeDifference = Math.abs(lng - fixedPointLongitude)
				let difference = (latitudeDifference + longitudeDifference) / 2

				if(difference >= coordinateDifferenceTolerance) continue
				if(difference >= smallestDifference) continue

				smallestDifference = difference
				index = i
			}

			return index
		}
	},
	computed: {
		...mapGetters([
			'getLatLng'
		])
	},
	watch: {
		getLatLng(val) {
			if(this.elevationChart && this.showChart) {
				const closest = this.closestCoordinateIndex(val.lat, val.lng);
				if(closest < 0) return;
        this.elevationChart.resetZoom();
				this.elevationChart.tooltip.setActiveElements([{
					datasetIndex: 0,
					index: closest,
				}])
				this.elevationChart.update();
				this.setMarker(this.coordinates[closest].latitude, this.coordinates[closest].longitude)
			}
		}
	},
	mounted() {
		this.$el.addEventListener('mouseout', this.removeCrosshair)
	},
	beforeDestroy() {
		this.hide();
		this.$el.removeEventListener('mouseout', this.removeCrosshair)
	}
}
</script>

<style>
.elevation-chart {
  height: 260px;
  position: absolute;
  margin: 0 10px;
  bottom: 10px;
  width: calc(100% - 20px);
  z-index: 1000;
}
.chart-container {
  height: 100%;
  width: 100%;
  position: relative;
	border-radius: 0.5rem;
	border: 3px solid #000000;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.75);
	background: rgba(100, 221, 129, 0.6);
	overflow: hidden;
}


#chartjs-tooltip table td {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

#chartjs-tooltip table td:nth-child(2) {
	font-weight: bold;
}

#chartjs-tooltip {
	color: black;
	font-size: 0.8rem;
	margin: 0 !important;
	border: black 2px solid;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75);
	border-radius: 0.5rem;
	background: rgba(255, 255, 255, 1);
	padding: 0.5rem;
	overflow: hidden;
	pointer-events : none;
}


.chart-close {
  position: absolute;
  top: -3rem;
  right: 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1001;
}
</style>
