<template>
  <div class="mx-2 py-1 d-flex">
    <div class="mr-2">
      <input  :id="id + ':input'" class="form-check-input" type="checkbox" :value="data.value" :checked="data.checked" @change="data.event(data.value, $event.target.checked)">
    </div>
    <label :for="id + ':input'" class="form-check-label align-self-center">{{ data.title }}</label>
  </div>
</template>

<script>
export default {
  name: "CheckboxElement",
  props: ['data'],
  data() {
    return {
      id: null
    };
  },
  created() {
    this.id = this._uid;
  }
}
</script>

<style scoped>

</style>
