<template>
  <div class="py-2">
    <div class="mx-2 font-weight-bold mb-3" v-if="data.title">{{ data.title }}</div>
    <div class="mx-2">
      <InputNumber
        v-model="value"
        :min="data.min"
        :max="data.max"
        :suffix='data.unit'
        inputClass="form-control"
        showButtons
        buttonLayout="horizontal"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        @input="onChange()"
        @blur="onBlur()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {Calculator} from "@/plugins/calculator";
export default {
  name: "InputTrueAltitudeElement",
  props: ['data'],
  data() {
    return {
      value: 0,
      changing: false,
      calculator: null,
    };
  },
  mounted(){
    this.calculator = new Calculator(this);
    console.log("MOUNTED");
  },
  watch: {
    getCurrentGPS(gps) {
      if(this.changing) return;
      if(!gps.alt) return;

      const alt = gps.alt;
      let offset = parseFloat(window.consentedStorage.getItem('altitudeOffset')  || '0');
      if(isNaN(offset)) offset = 0;

      this.$data.value = this.convertFromTrueValue(alt + offset);
    },
  },
  methods: {
    onChange() {
      this.changing = true;
      const gps = this.getCurrentGPS;
      if(!(gps || {}).alt) return;
      const alt = gps.alt;
      const val = this.convertToTrueValue(this.value);
      const offset = Math.round(val - alt);
			window.consentedStorage.setItem("altitudeOffset", offset);
      window.loadAppLink(`r2gflightserver://set-altitude-offset?offset=${offset}`)
    },
    onBlur(){
      this.changing = false;
    },
    convertToTrueValue(v) {
        if(!v) return 0;
        if(v instanceof String) {
          v = parseFloat(v);
        }

        if(isNaN(v)) v = 0;

        let elevationUnit = window.consentedStorage.getItem('elevationUnit');
        if(!elevationUnit || elevationUnit === 'ft') {
          return Math.floor(v * 0.3048);
        }
        if(elevationUnit === 'm') {
          return Math.floor(v);
        }
        return 0;
    },
    convertFromTrueValue(trueValue) {
        if(!trueValue) return 0;
        if(trueValue instanceof String) {
          trueValue = parseFloat(trueValue);
        }
        if(isNaN(trueValue)) trueValue = 0;

        let elevationUnit = window.consentedStorage.getItem('elevationUnit');
        if(!elevationUnit || elevationUnit === 'ft') {
          return Math.floor(trueValue / 0.3048);
        }
        if(elevationUnit === 'm') {
          return Math.floor(trueValue);
        }
        return 0;
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentGPS'
    ]),
  },
  created() {
    if(this.data.default !== undefined) {
      this.value = Math.min(this.data.default, this.data.max);
    }
    else {
      this.value = this.data.max;
    }
  }
}
</script>

<style scoped>

</style>
