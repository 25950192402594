<template>
	<a class="sidemenu-item d-flex " href="javascript:void(0)" @click="onMenuClick">
		<span class="flex-1 d-flex align-items-center">
			<font-awesome-icon :icon="data.icon" size="lg" :fixed-width="true" class="mr-2" v-if="data.icon" />
			{{ $t('settings.' + data.title) }}
		</span>
		<div class="d-flex align-items-center" v-if="data.help">
			<div class="help-wrapper">
				<help-button :help-name="data.help" :inverted-color="true" />
			</div>
		</div>
	</a>
</template>

<script>
import HelpButton from '@/components/help/HelpButton.vue';

export default {
	name: "RightMenuElement",
	components: {
		HelpButton
	},
	props: ['data'],
	methods: {
		onMenuClick(e) {
			const target = e.target;

			if (target.matches(".help-wrapper, .help-wrapper *")) {
				return;
			}
			this.data.event();
		}
	}
}
</script>

<style scoped>
.sidemenu-item {
	border-bottom: 1px solid #ccc;
	color: #000000;
	display: block;
	padding: 0 15px;
}

.sidemenu-item span {
	padding: 20px 0;
}


.sidemenu-item:active,
.sidemenu-item:hover {
	text-decoration: none;
}




.sidemenu-item:hover,
.sidemenu-item:active{
	color: white;
	background: #0468c2;
}

.sidemenu-item:hover .help-wrapper *,
.sidemenu-item:active .help-wrapper * {
	color: white !important;
}

.help-wrapper {
	height: 100% !important;
	display: flex;
	position: relative;
	align-items: center;
	padding: 0 7px;
}

</style>
