<template>
	<div class="p-3 py-4 transparent-modal" style="max-height: 100vh; overflow-y: auto;height: 100%;min-height: 400px;background: none" @click.self="$emit('close')">
		<div class="close-btn" v-on:click="$emit('close')">
			<font-awesome-icon class="m-auto" icon="times" />
		</div>


		<div class="add-modal-body">
			<h4>{{ $t('directToModal.addDirectTo') }}</h4>
			<AutoComplete id="searchBox" class="w-100 full-auto-complete" v-model="selectedWaypointTitle" @item-select="$e => onSelect($e.value)" field="name" :suggestions="suggestedMapObjects" @complete="searchMapObjects" >
				<template #item="option">
					<div class="d-flex justify-content-between">
						<span>{{ waypointName(option.item) }}</span>
						<span v-if="option.item.distance">
								{{ option.item.distance | formatNumber }} {{ distanceUnit  | unitSuffix }}
						</span>
					</div>
				</template>
			</AutoComplete>
		</div>

	</div>
</template>

<script>
import { getPlaneIcon } from "@/plugins/planeUtils";
import Fuse from 'fuse.js';
import PlaneCard from "@/components/plane/PlaneCard.vue";
import AutoComplete from "primevue/autocomplete";
import { calcDistance } from "@/plugins/calculator";
import { LOCAL_STORAGE_SERVICE, READ_JSON_FILE, STORAGE_KEY } from "@/plugins/utils";
import { mapGetters } from "vuex";

export default {
	name: 'DirectToModal',
	components: { AutoComplete },
	emits: ['close'],
	data() {
		return {
			/** @type {string} */
			search: "",
			/** @type {Fuse<Plane>} */
			fuse: null,
			/** @type {Plane[]} */
			filtered: [],
			searchTimeout: null,
			/** @type {NavObjectPayload[]} */
			mapObjects: [],
			/** @type {NavObjectPayload[]} */
			suggestedMapObjects: [],
			selectedWaypointTitle: '',

			/** @type {DistanceUnitType} */
			distanceUnit: window.consentedStorage.getItem('distanceUnit') || 'km',
		}
	},
	computed: {
		...mapGetters([
			'getCurrentGPS'
		]),
	},
	watch: {

	},
	methods: {
		/** @param {NavObjectPayload} mapObject */
		onSelect(mapObject) {
			this.$emit('close', mapObject)
		},
		waypointName(item) {
			if (item.type === 'CITY' || item.type === 'NAVIGATION_POINT') {
				return item.title;
			}
			return item.title + ' (' + item.name + ')';
		},
		searchMapObjects(event) {
			this.suggestedMapObjects = this.mapObjects
				.filter(obj => {
					return this.waypointName(obj).fullNormalize().trim().startsWith(event.query.fullNormalize());
				})
				.map(obj => {
					let distance = null;
					if(this.getCurrentGPS) {
						distance = this.$units.convert(calcDistance(this.getCurrentGPS.lat, this.getCurrentGPS.lng, obj.latitude, obj.longitude), 'km', this.distanceUnit)
					}
					return {
						...obj,
						distance,
					}
				});
		},

	},
	async mounted() {
		this.mapObjects = await this.$rest.getAllObjects();

		document.getElementById("searchBox").focus()
		window.loadAppLink("keyboard-focus");
	}
}
</script>

<style scoped>
.add-modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	background: white;
	border-radius: 0.6rem;
}


.search-input input {
	border: none;
	background-color: transparent;
	width: 100%;
	padding: 0.5rem 1rem;
}



.close-btn {
	position: absolute;
	top: 32px;
	right: 22px;
	z-index: 1;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}

h4 {
	color: black;
	padding: 1rem;
	margin: 0;
}
</style>
