<template>
  <div class="py-2">
    <div class="mx-2 font-weight-bold mb-3" v-if="data.title">{{ data.title }}</div>
    <div class="mx-2">
      <InputNumber
        v-model="value"
        :min="data.min"
        :max="data.max"
        :suffix='data.unit'
        inputClass="form-control"
        showButtons
        buttonLayout="horizontal"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        @input="data.event(value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputNumberElement",
  props: ['data'],
  data() {
    return {
      value: 0
    };
  },
  created() {
    if(this.data.default !== undefined) {
      this.value = Math.min(this.data.default, this.data.max);
    }
    else {
      this.value = this.data.max;
    }
  }
}
</script>

<style scoped>

</style>
