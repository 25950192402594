export function NavPointManager(map, rest, viewManager) {
	this.map = map;
  this.rest = rest;
	this.viewManager = viewManager;
  this.navpoints = [];
  this.markers = [];


  this.getIconForNavPoint = function (navpoint){

    let iconUrl = require('@/assets/navpoint.png');
    // if(something) {
    //   iconUrl = require('@/assets/navpoint_transparent.png');
    // }
    if(navpoint.type == "RADIO_TRANSMITTER") {
      iconUrl = require('@/assets/radio_bordered.png');

      if(!navpoint.name.includes('DVOR')) {
        iconUrl = require('@/assets/radio.png');
      }
    }



    const icon = window.L.icon({
      iconUrl: iconUrl,
      iconSize: [20, 20],
      iconAnchor: [10, 10],
    });
    return icon;
  }


  this.makeTooltipContent = function (navpoint) {
    let table;

    if(navpoint.type == 'RADIO_TRANSMITTER') {
      table = [
        {
          key: this.viewManager.$t('navpoint.frequency') + ':',
          value: navpoint.frequency + ' Mhz',
        },
        {
          key: this.viewManager.$t('navpoint.declination') + ':',
          value: navpoint.notes,
        },
        {
          key: this.viewManager.$t('navpoint.altitude') + ':',
          value: this.viewManager.calculator.elevationFromFeet(navpoint.altitude),
        },
        {
          key: this.viewManager.$t('navpoint.radius') + ':',
          value: navpoint.radius,
        }
      ];
    }
    else {
      table = [];

      if(navpoint.notes) {
        table.push({
          key: this.viewManager.$t('navpoint.notes') + ':',
          value: navpoint.notes == 'Nil' ? this.viewManager.$t('navpoint.empty') : navpoint.notes ,
        });
      }
    }

    return `
          <span class="tooltip-title">
            ${navpoint.name}
          </span>
          <table class="table table-sm mb-0">
            ${table.map((i) => {
              return `<tr>
                  <th>${i.key}</th>
                  <td class="">
                    ${i.value}
                  </td>
                </tr>`;
            }).join('\n')}
          </table>
      `
  }


	this.loadNavPoints = async function() {
		const navpoints = await this.rest.getNavPoints();
		this.navpoints = navpoints || [];
	};

  this.updateMarkers = function () {
    let z = this.viewManager.map.getZoom();
    this.markers.forEach(marker => {
      let icon = marker.options.icon;

      let iconsize = 20

      if(z > 10) {
        iconsize = 20;
      }else if(z > 8) {
        iconsize = 16;
      }else  if(z > 6) {
        iconsize = 12;
      }

      if(z > 9) {
        marker.openTooltip();
      }else{
        marker.closeTooltip();
      }

      icon.options.iconSize = [iconsize, iconsize];
      marker.setIcon(icon);
    })
  }


	this.toggleNavPoints = function(on) {
    if(this.markers.length == 0) {
      this.loadMarkers();
  }
    this.markers.forEach(marker => {
      if(on){
        marker.addTo(this.map)
      }else{
        marker.remove()
      }
    })

    this.updateMarkers();
		window.consentedStorage.setItem('toggleNavPoints', on ? "1": "0");
	};

  this.loadMarkers = function () {
    (this.navpoints || []).forEach(navpoint => {
      let marker = window.L.marker([navpoint.latitude, navpoint.longitude], {
        rotateWithView: true,
        icon: this.getIconForNavPoint(navpoint),
      });

      if(navpoint.type == "RADIO_TRANSMITTER") {
        marker.setRotation(navpoint.declination * (Math.PI / 180));
      }

      marker.bindTooltip(navpoint.type == "RADIO_TRANSMITTER" ? navpoint.code : navpoint.name, {
        direction: "center",
        permanent: true,
        className: 'name-tooltip',
        offset: [0, navpoint.type == "RADIO_TRANSMITTER" ? 20 : 15]
      })

      this.viewManager.tooltipManager.add(
				marker,
				this.map,
				(obj) => { // textFN
					return this.makeTooltipContent(navpoint);
				},
				(obj) => { // onShow
				},
				(obj) => { // onHide
				},
				(obj, latLng, text) => { // onClick
          if (!this.viewManager.draggingMarker) {
            this.viewManager.$emit('mapClick', latLng, text);
          }
				}
			);

      marker.addTo(this.map);
      this.markers.push(marker);
    });
  }

  this.loadSettings = function () {
    this.toggleNavPoints(["1", null].includes(window.consentedStorage.getItem('toggleNavPoints')))
  }
}
