export function MapLayerManager(viewManager, defaultMapLayer) {

	this.viewManager = viewManager;
	this.mapLayer = defaultMapLayer || 'osm_full';
	if(window.inIframe) {
		this.mapLayer = 'base';
	}

	this.mobile = 'ios' === window.platform || 'android' === window.platform;

	this.layerInfo = {
		baseLayer: window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '',
			minZoom: 0,
			maxZoom: 18,
			id: 'osm',
			tileSize: 256
		}),
		layers: {
			'base': {
				title: this.viewManager.$t('maplayer.openflightmaps') + (this.mobile ? ' (offline)' : ''),
				userBaseLayer: true,
				url(country) {
					return 'tiles/countries/' + country + '/base/{z}/{x}/{y}.png';
				},
				layerOptions: {
					attribution: '',
					maxZoom: 11,
					id: 'base',
					tileSize: 256
				},
			},
			'merged': {
				title: this.viewManager.$t('maplayer.openflightmaps-with-airspaces') + (this.mobile ? ' (offline)' : ''),
				userBaseLayer: true,
				url(country) {
					return 'tiles/countries/' + country + '/merged/{z}/{x}/{y}.png';
				},
				layerOptions: {
					attribution: '',
					maxZoom: 11,
					id: 'base',
					tileSize: 256
				},
			},
			'osm_full': {
				title: this.viewManager.$t('maplayer.openstreetmaps') + (this.mobile ? ' (online)' : ''),
				userBaseLayer: false,
				layer: window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
					attribution: '',
					minZoom: 0,
					maxZoom: 18,
					id: 'osm_full',
					tileSize: 256
				})
			},
			...(((viewManager.$store.getters.getLoggedInUserData || {}).types || []).includes("SATELLITE_MAP") ? {
				'satellite': {
					title: this.viewManager.$t('maplayer.satellite') + (this.mobile ? ' (online)' : ''),
					userBaseLayer: false,
					layer: window.L.tileLayer('https://api.tomtom.com/map/1/tile/sat/main/{z}/{x}/{y}.jpg?key=a9OtSjYwh5iJyJAMdf1nLDTNPMxmoz8a', {
						attribution: '',
						minZoom: 0,
						maxZoom: 18,
						id: 'satellite',
						tileSize: 256
					})
				},
			} : {})
		}
	};

	this.buildListForRadios = function() {
		let l = [];

		for(let key in this.layerInfo.layers) {
			l.push({ title: this.layerInfo.layers[key].title, value: key });
		}

		return l;
	}

	this.swapLayers = function(save) {
		this.layerInfo.baseLayer.addTo(this.viewManager.map);
		// if(layer.userBaseLayer) {
		//
		// }
		// else {
		// 	this.layerInfo.baseLayer.remove();
		// }
		for(let key in this.layerInfo.layers) {
			let layer = this.layerInfo.layers[key];
			if((layer || {}).layer) {
				layer.layer.remove();
			}
		}

		let layer = this.layerInfo.layers[this.mapLayer];
		console.log('Swapping layer to ' + this.mapLayer, layer);
		if(layer) {
			if(layer.url) {
				const layers= [];
				let tiles = JSON.parse(window.localStorage.getItem('tiles') || '["hu"]');
				if(window.platform === 'web') {
					tiles = window.allTiles || [];
				}
				for(let i = 0; i < tiles.length; i++) {
					layers.push(window.L.tileLayer(layer.url(tiles[i]), layer.layerOptions));
					console.log('Added layer for country ' + tiles[i]);
				}
				layer.layer = window.L.layerGroup(layers);
			}


			this.layerInfo.layers[this.mapLayer].layer = layer.layer;
			layer.layer.addTo(this.viewManager.map);




		}

		if(save) {
			window.consentedStorage.setItem('mapLayer', '' + this.mapLayer);
		}
	};

	this.loadSettings = function() {
		let v = window.consentedStorage.getItem('mapLayer');

		if(this.viewManager.$route.query.layer) {
			v = this.viewManager.$route.query.layer;
		}

		if(v === '1') {
			this.mapLayer = 'base';
		}
		else if(v === '2') {
			this.mapLayer = 'merged';
		}
		else if(v === '3') {
			this.mapLayer = 'osm_full';
		}
		else if(v) {
			this.mapLayer = v;
		}
	};


	this.loadLayer = function(layer) {
    this.mapLayer = layer;
    this.swapLayers(false);
	};
}
