import SliderElement from "@/components/menu/menuComponent/SliderElement";
import CheckboxElement from "@/components/menu/menuComponent/CheckboxElement";
import { getPlaneIcon } from "@/plugins/planeUtils";

export function FlyingObjectManager(viewManager) {

    this.viewManager = viewManager;

    this.flyingObjects = {};

    this.maxElev = 10000;
    this.maxElevFilterSet = false;
    this.maxElevFilter = 10000;
		this.maxViewRange = 100;
    this.maxAllowedElevFilter = 10000;
    this.internalFilter = true;

    this.offlineTimeout = 30000;
    this.removeTimeout = 60000;
    this.removeTimeoutWhenFlying = 30000;

    this.updateInterval = 1000;
    this.clearInterval = null;

    this.transponderTypes = {
        'ADS-B': true,
        'FLARM': true,
        'MODE-S': false,
        'OGN': true
    };

    this.beaconTypes = {
        'UNKNOWN': 'beaconType.UNKNOWN',
        'GLIDER': 'beaconType.GLIDER',
        'PARA_GLIDER': 'beaconType.PARA_GLIDER',
        'HAND_GLIDER': 'beaconType.HAND_GLIDER',
        'HELICOPTER': 'beaconType.HELICOPTER',
        'UAV': 'beaconType.UAV',
        "CAR": 'beaconType.CAR',
        'PARACHUTE': 'beaconType.PARACHUTE',
        'MOTORPLANE': 'beaconType.MOTORPLANE',
        'UFO': 'beaconType.UFO',
        'JET': 'beaconType.JET',
        'AIRSHIP': 'beaconType.AIRSHIP',
        'BALLOON': 'beaconType.BALLOON',
        'GYROCOPTER': 'beaconType.GYROCOPTER',
        'FLEX_WING_TRIKES': 'beaconType.FLEX_WING_TRIKES',
        'PARA_MOTOR': 'beaconType.PARA_MOTOR',
        'THREE_AXES_LIGHT_PLANE': 'beaconType.THREE_AXES_LIGHT_PLANE'
    };

    (() => {
        let _str = window.consentedStorage.getItem('maxTrafficElevFilter');
        if (_str) {
            this.maxElevFilter = parseInt(_str);
            this.maxElevFilter = Math.min(this.maxAllowedElevFilter, this.maxElevFilter);
            this.maxElevFilterSet = true;
        }

				_str = window.consentedStorage.getItem('maxTrafficViewRange');
				if (_str) {
					this.maxViewRange = parseInt(_str);
					this.maxViewRange = Math.min(1000, this.maxViewRange);
				}

        _str = window.consentedStorage.getItem('enableTransponderTypes');
        if (_str) {
            let o = JSON.parse(_str);
            for (let tt in o) {
                this.transponderTypes[tt] = o[tt] === true;
            }
        }

        _str = window.consentedStorage.getItem('trafficInternalFilter');
        if (_str === 'true') {
            this.internalFilter = true;
        }
        else if (_str === 'false') {
            this.internalFilter = false;
        }

        this.clearInterval = setInterval(() => {
          this.clearOldFlyingObjects();
        }, this.updateInterval)
    })();

    this.getIconAtZoom = function (z, type) {
        let iconSize;

        if (z < 10) {
            iconSize = 20;
        }
        else if (z < 11) {
            iconSize = 24;
        }
        else if (z < 12) {
            iconSize = 28;
        }
        else if (z < 13) {
            iconSize = 32;
        }
        else if (z < 14) {
            iconSize = 36;
        }
        else if (z < 15) {
            iconSize = 40;
        }
        else if (z < 16) {
            iconSize = 44;
        }
        else if (z < 17) {
            iconSize = 48;
        }
        else {
            iconSize = 52;
        }

        let multiplier = 1;

        let url = getPlaneIcon(type);

        if (type === 'GLIDER' || type === 'CAR') {
            multiplier = 1.1;
        }
        else if (type === 'JET' || type === 'UFO') {
            multiplier = 1.2;
        }

        iconSize = Math.round(iconSize * multiplier);

        return this.viewManager.iconCache.getIcon(url, iconSize, iconSize, iconSize / 2, iconSize / 2);
    };

    this.updateIcons = function () {
        let z = this.viewManager.map.getZoom();
        for (let registrationNumber in this.flyingObjects) {
            let flyingObject = this.flyingObjects[registrationNumber];
            if(flyingObject.data.registrationNumber.includes("UFO")) flyingObject.data.beaconType = "UFO"; ///UFO EASTER EGG
            let icon = this.getIconAtZoom(z, flyingObject.data.beaconType);
            flyingObject.gpsMarker.setIcon(icon);

            if (z > 9) {
                flyingObject.gpsMarker.openTooltip();
            }
            else {
                flyingObject.gpsMarker.closeTooltip();
            }
        }
    };

    this.isFlyingObjectSelected = function (flyingObject) {
        if (!flyingObject) return false;
        if(this.viewManager.tooltipManager.tooltip.isOpen()) return (((this.viewManager.tooltipManager.tooltip.obj || {}).foData || {}).data || {}).registrationNumber === flyingObject.registrationNumber;
        return (((this.viewManager.tooltipManager.currentObj || {}).foData || {}).data || {}).registrationNumber === flyingObject.registrationNumber;
    }

    this.shouldRemove = function (lastUpdated) {
        return new Date().getTime() - new Date(lastUpdated).getTime() > (this.viewManager.showCurrentGPS ? this.removeTimeoutWhenFlying : this.removeTimeout);
    }

    this.isOffline = function (lastUpdated) {
        return new Date().getTime() - new Date(lastUpdated).getTime() > this.offlineTimeout;
    }

    this.makeTooltipText = function (marker) {
			if(marker.foData.data.transponderType === 'INTERNAL') {
				marker.foData.data.transponderType = "PILOTNET"
			}
        return  `
					<div style="white-space: nowrap;display: flex; align-items:center;gap: 0.5rem">
						<b>${marker.foData.data.registrationNumber}</b>
						${marker.foData.data.source != 'SAFE_SKY' ?  '' : `
								<a href="https://www.safesky.app" target="_blank" style="position: relative; display: inline-block">
									<img src="${require('@/assets/safesky_simple.png')}" alt="SafeSky" title="SafeSky" class="img-fluid" style="max-width: 15px;" />
								</a>
						`}
					</div>
					<div style="white-space: nowrap">
						<span class="mr-1">${this.viewManager.calculator.elevationFromFeet(marker.foData.data.altitude)}</span>
						<span class="mr-1">${this.viewManager.calculator.speedFromKmH(marker.foData.data.speed)}</span>
						${!marker.foData.data.grounded ? '' : `
							<span class="text-danger">${this.viewManager.$t('flyingobject.grounded')}</span>
						`}
					</div>

					${!marker.foData.data.beaconType ? '' : `
						<div style="white-space: nowrap">
							${this.viewManager.$t(this.beaconTypes[marker.foData.data.beaconType])}
						</div>
					`}
					${!marker.foData.data.transponderType ? '' : `
						<div style="white-space: nowrap">
							${this.viewManager.$t('flyingobject.transponder-type')} ${marker.foData.data.transponderType}
						</div>
					`}
					<div style="white-space: nowrap">
						${this.viewManager.$t('flyingobject.updated-at')}
						<span class="${this.isOffline(marker.foData.data.updateTime) ? 'text-danger' : ''}">${window.formatTime(marker.foData.data.updateTime)}</span>
					</div>
				`;
    };

    this.onOpenPlane = async function (marker) {
        this.viewManager.selectedMarker = marker;

				const data = await this.viewManager.$rest.getCoordinatesOfFlyingObject(marker.foData.data.registrationNumber);
				if(!data) return;
				if (!data.routeId) return;
				marker.foData.routeId = data.routeId;
				let polyline = this.viewManager.addRoute(data);
				this.viewManager.registerToRouteUpdates(data.routeId);

				if (this.viewManager.showElevationChart) {
					this.viewManager.$refs.elevationChart.refresh(data.coordinates, true);
				}
    };

    this.onClosePlane = function (marker, becauseOpenedAnother) {
        if (marker.foData.routeId) {
            this.viewManager.unregisterFromRouteUpdates(marker.foData.routeId);
            this.viewManager.removeRoute(marker.foData.routeId);
        }

        if (!becauseOpenedAnother && this.viewManager.showElevationChart) {
            this.viewManager.toggleElevationChart();
        }
    };




    this.moveGpsMarkerTo = function (gpsMarker, latLng, angle) {
        gpsMarker.setLatLng(latLng);

        if (angle === 0 || angle && angle != -1.0) {

            if(!(((gpsMarker.getIcon() || {}).options || {}).iconUrl || '').includes('ufo')) {
              gpsMarker.setRotation(angle * (Math.PI / 180.0));
            }
        }

        if (this.viewManager.followGps && gpsMarker == this.viewManager.selectedMarker && this.viewManager.canFollowTraffic) {
            this.viewManager.map.panTo(latLng);
        }
    };

    this.clearOldFlyingObjects = function () {
        let registrationNumbersToRemove = [];
        for (let registrationNumber in this.flyingObjects) {
            let flyingObject = this.flyingObjects[registrationNumber];
            if (this.shouldRemove(flyingObject.data.updateTimeObj) && !this.isFlyingObjectSelected(flyingObject.data)) {
                registrationNumbersToRemove.push(registrationNumber);
            }
            if (this.isOffline(flyingObject.data.updateTimeObj) && this.isFlyingObjectSelected(flyingObject.data)) {
                this.viewManager.tooltipManager.refreshTooltip()
            }
        }

        for (let registrationNumber of registrationNumbersToRemove) {
            let flyingObject = this.flyingObjects[registrationNumber];
            //console.log("REMOVE: " + registrationNumber, now - flyingObject.data.updateTimeObj.getTime());
            flyingObject.gpsMarker.remove();

            if (this.viewManager.selectedMarker != null && this.viewManager.selectedMarker == flyingObject.gpsMarker) {
                this.onClosePlane(flyingObject.gpsMarker, false);
            }

            delete this.flyingObjects[registrationNumber];
        }
    };

    this.updateLocationOfFlyingObject = function (flyingObject) {
        if (this.viewManager.registrationNumber && this.viewManager.registrationNumber === flyingObject.registrationNumber) {
            return;
        }

        if(!this.canShow(flyingObject)) return;

        let foData = this.flyingObjects[flyingObject.registrationNumber];
        const isOffline = this.shouldRemove(flyingObject.updateTime);
        if (!foData) {
            if (isOffline && !this.isFlyingObjectSelected(flyingObject)) {
                return;
            }
            let z = this.viewManager.map.getZoom();
            let icon = this.getIconAtZoom(z, flyingObject.beaconType);

            foData = {
                data: flyingObject,
                gpsMarker: window.L.marker([flyingObject.latitude, flyingObject.longitude], {
                    rotateWithView: true,
                    icon: icon,
                    interactive: true
                })
            };

            foData.gpsMarker.bindTooltip(flyingObject.registrationNumber + '<br/>' + this.viewManager.calculator.elevationFromFeet(flyingObject.altitude), {
                direction: "center",
                permanent: true,
                className: 'name-tooltip',
                offset: [0, 30]
            });

            if (this.canShow(flyingObject)) {
                if (!foData.map) {
                    foData.gpsMarker.addTo(this.viewManager.map);
                    foData.map = this.viewManager.map;
                }
            }
            else {
                if (foData.map) {
                    foData.gpsMarker.remove();
                    foData.map = null;
                }
            }

            foData.gpsMarker.foData = foData;

            this.viewManager.tooltipManager.add(
                foData.gpsMarker,
                this.viewManager.map,
                (obj) => { // textFN
                    return this.makeTooltipText(obj);
                },
                (obj) => { // onShow
                    this.onOpenPlane(obj);
                },
                (obj) => { // onHide
                    this.onClosePlane(obj, false); // TODO: Itt a második paraméter lehet nem jó.
                },
                (obj, latLng, text) => { // onClick
                },
                false, // onlyClick
                true,	// onlyClickShowHideEvents,
								"100px" // width
            );

            if (this.viewManager.map.getZoom() <= 9) {
                foData.gpsMarker.closeTooltip();
            }

            this.flyingObjects[flyingObject.registrationNumber] = foData;
        }
        else {
            foData.data = flyingObject;
            foData.gpsMarker.setTooltipContent(flyingObject.registrationNumber + '<br/>' + this.viewManager.calculator.elevationFromFeet(flyingObject.altitude));
            if (foData.gpsMarker.tooltipOpened) {
                foData.gpsMarker.refeshTooltip();
            }
        }

        foData.data.updateTimeObj = new Date(foData.data.updateTime);

        this.moveGpsMarkerTo(foData.gpsMarker, [foData.data.latitude, foData.data.longitude], foData.data.course);
    };

		this.updateLocationOfFlyingObjects = function (flyingObjects) {
				for (let flyingObject of flyingObjects) {
						this.updateLocationOfFlyingObject(flyingObject);
				}
		};

    this.makeRightMenuComponents = function () {

        let l = [
            {
                component: SliderElement,
                props: {
                    title: this.viewManager.$t('flyingobject.max-height-of-traffix'),
                    default: this.maxElevFilter,
                    step: 100,
                    min: 0,
                    max: Math.min(this.maxAllowedElevFilter, this.maxElev),
                    unit: 'FT',
                    event: (elev) => {
                        this.toggleTraffic({
														elevation: elev,
														range: this.maxViewRange
												});
                    }
                }
            },
        ];

				if(this.viewManager.showCurrentGPS) {
					l.push({
						component: SliderElement,
						props: {
							title: this.viewManager.$t('flyingobject.max-viewrange'),
							default: this.maxViewRange,
							step: 10,
							min: 0,
							max: 1000,
							unit: 'KM',
							event: (range) => {
								this.toggleTraffic({
									elevation: this.maxElevFilter,
									range: range
								});
							}
						}
					});
				}

        l.push({
            component: CheckboxElement,
            props: {
                title: 'PilotNet',
                value: 'INTERNAL',
                checked: this.internalFilter,
                event: (type, on) => {
                    this.internalFilter = on;
                    window.consentedStorage.setItem('trafficInternalFilter', this.internalFilter);
                    this.toggleTraffic();
                }
            }
        });

        for (let transponderType in this.transponderTypes) {
            l.push({
                component: CheckboxElement,
                props: {
                    title: transponderType,
                    value: transponderType,
                    checked: this.transponderTypes[transponderType],
                    event: (type, on) => {
                        this.transponderTypes[type] = on;
                        window.consentedStorage.setItem('enableTransponderTypes', JSON.stringify(this.transponderTypes));
                        this.toggleTraffic();
                    }
                }
            });
        }

        return l;
    };
    this.canShow = function (flyingObject) {
				if(!flyingObject.registrationNumber) {
					return false;
				}

				if (flyingObject.altitude > this.maxElevFilter) {
					return false;
				}

        if (flyingObject.source === 'INTERNAL') {
            if (!this.internalFilter) {
                return false;
            }
        }
        else if (!this.transponderTypes[flyingObject.transponderType]) {
            return false;
        }

        return true;
    };

	/**
	 *
	 * @param {{elevation: number, range: number}} [filters]
	 */
    this.toggleTraffic = function (filters) {
				if(filters) {
					this.maxElevFilter = filters.elevation || this.maxElevFilter
					this.maxViewRange = filters.range || this.maxViewRange

					window.consentedStorage.setItem('maxTrafficElevFilter', '' + this.maxElevFilter);
					window.consentedStorage.setItem('maxTrafficViewRange', '' + this.maxViewRange);
				}



				this.viewManager.registerToFlyingObjectUpdates();

        for (let registrationNumber in this.flyingObjects) {
            let flyingObject = this.flyingObjects[registrationNumber];
            if (this.canShow(flyingObject.data)) {
                flyingObject.gpsMarker.addTo(this.viewManager.map);
            }
            else {
                flyingObject.gpsMarker.remove();
            }
        }
    }

    this.destroy = function () {
      if(this.clearInterval) clearInterval(this.clearInterval)
    }
}
