export function IconCache() {

	this.cache = { };

	this.getIcon = function(url, iconWidth, iconHeight, anchorX, anchorY) {
		let key = url + 'Đ' + iconWidth + 'Đ' + iconHeight + 'Đ' + anchorX + 'Đ' + anchorY;
		let icon = this.cache[key];
		if(!icon) {
			//console.log("new icon", iconWidth, url);
			icon = window.L.icon({
				iconUrl: url,
				iconSize: [iconWidth, iconHeight],
				iconAnchor: [anchorX, anchorY]
			});
			this.cache[key] = icon;
		}
		return icon;
	};

}